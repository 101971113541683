import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_EMAIL_PASSWORD,
  LOGIN_USER_FACEBOOK,
  LOGIN_USER_LINKEDIN,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGIN_COMPANY,
  LOGIN_COMPANY_SUCCESS,
  LOGIN_COMPANY_EMAIL_PASSWORD,
  LOGIN_COMPANY_ERROR,
  REGISTER_COMPANY,
  REGISTER_COMPANY_SUCCESS,
  REGISTER_COMPANY_ERROR,
  LOGOUT_COMPANY,
  FORGOT_PASSWORD_COMPANY,
  FORGOT_PASSWORD_SUCCESS_COMPANY,
  FORGOT_PASSWORD_ERROR_COMPANY,
  RESET_PASSWORD_COMPANY,
  RESET_PASSWORD_SUCCESS_COMPANY,
  RESET_PASSWORD_ERROR_COMPANY,
  LOGIN_SOCIAL_LINKEDIN,
  LOGIN_SOCIAL_LINKEDIN_SUCCESS,
  LOGIN_SOCIAL_LINKEDIN_ERROR,
  LOGIN_SOCIAL,
} from '../actions';

/* USER */ 
export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const loginSocial = (user, history) => ({
  type: LOGIN_SOCIAL,
  payload: { user, history },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const loginUserEmailPassword = (user, history) => ({
  type: LOGIN_USER_EMAIL_PASSWORD,
  payload: {user, history}
})

export const loginUserFacebook = (history) => ({
  type: LOGIN_USER_FACEBOOK,
  payload: history
})

export const loginUserLinkedin = (user, history) => ({
  type: LOGIN_USER_LINKEDIN,
  payload: {user, history}
})

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

/* COMPANY */

export const loginCompany = (company, history) => ({
  type: LOGIN_COMPANY,
  payload: { company, history },
});
export const loginCompanySuccess = (company) => ({
  type: LOGIN_COMPANY_SUCCESS,
  payload: company,
});
export const loginCompanyError = (message) => ({
  type: LOGIN_COMPANY_ERROR,
  payload: { message },
});

export const loginLinkedIn = (code, state) => ({
  type: LOGIN_SOCIAL_LINKEDIN,
  payload: { code, state },
});
export const loginLinkedInSuccess = (company) => ({
  type: LOGIN_SOCIAL_LINKEDIN_SUCCESS,
  payload: company,
});
export const loginLinkedInError = (message) => ({
  type: LOGIN_SOCIAL_LINKEDIN_ERROR,
  payload: { message },
});


export const loginCompanyEmailPassword = (company, history) => ({
  type: LOGIN_COMPANY_EMAIL_PASSWORD,
  payload: {company, history}
})


export const forgotPasswordCompany = (forgotCompanyMail, history) => ({
  type: FORGOT_PASSWORD_COMPANY,
  payload: { forgotCompanyMail, history },
});
export const forgotPasswordSuccessCompany = (forgotCompanyMail) => ({
  type: FORGOT_PASSWORD_SUCCESS_COMPANY,
  payload: forgotCompanyMail,
});
export const forgotPasswordErrorCompany = (message) => ({
  type: FORGOT_PASSWORD_ERROR_COMPANY,
  payload: { message },
});

export const resetPasswordCompany = ({ resetPasswordCodeCompany, newPasswordCompany, history }) => ({
  type: RESET_PASSWORD_COMPANY,
  payload: { resetPasswordCodeCompany, newPasswordCompany, history },
});
export const resetPasswordSuccessCompany = (newPasswordCompany) => ({
  type: RESET_PASSWORD_SUCCESS_COMPANY,
  payload: newPasswordCompany,
});
export const resetPasswordErrorCompany = (message) => ({
  type: RESET_PASSWORD_ERROR_COMPANY,
  payload: { message },
});

export const registerCompany = (company, history) => ({
  type: REGISTER_COMPANY,
  payload: { company, history },
});
export const registerCompanySuccess = (company) => ({
  type: REGISTER_COMPANY_SUCCESS,
  payload: company,
});
export const registerCompanyError = (message) => ({
  type: REGISTER_COMPANY_ERROR,
  payload: { message },
});

export const logoutCompany = (history) => ({
  type: LOGOUT_COMPANY,
  payload: { history },
});
