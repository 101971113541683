import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { 
  auth,
  facebookAuthProvider,
  firebase } from '../../helpers/Firebase';
  import { isCompany, setLoginData} from '../../helpers/Utils'
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGIN_USER_FACEBOOK,
  LOGIN_COMPANY,
  REGISTER_COMPANY,
  LOGIN_SOCIAL_LINKEDIN,
  LOGIN_SOCIAL,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  loginLinkedInSuccess,
  loginLinkedInError,
} from './actions';

import { adminRoot, currentUser } from "../../constants/defaultValues"
import { setCurrentUser } from '../../helpers/Utils';
import { UserRole } from '../../helpers/authHelper';
import { fetchConToken, fetchSinToken } from '../../helpers/fetch';
import { showNotification } from '../notifications/actions';
import { NotificationTypes } from '../../constants/UIConstants';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then( async (user) => {
      try{
        const resp = await fetchSinToken('auth', {email, password},'POST') 
        const body = await resp.json();
          if( body.ok ) {
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime() );
            body.verificada = true; // REMOVE ME DEVELOPMENT // user?.user?.emailVerified
            return body
        } else {
          window.alert( body?.msg );
        }

      } catch (error) {

        window.alert( error );
      }
  })
    .catch((error) => error);


function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
      const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
      if (!loginUser?.message&& loginUser?.uid) {
        if( loginUser.verificada)
          {
        const item = { uid: loginUser.uid, displayName: loginUser.name, role: loginUser.role, /* ...currentUser */};
        //console.log("Item log: ", item);
        setCurrentUser(item);
        yield put(loginUserSuccess(item));
        setLoginData(email,password,false);
        if(history)
      {  history.push(`${adminRoot}/user/ofertas`);}
    }
    else if(!loginUser.verificada) {
      yield put(loginUserError('Verifique su cuentra a través del correo electrónico'));
        }
      } 
          else{
            yield put(loginUserError(loginUser?.message));
          }
    } catch (error) {
      yield put(loginUserError(error));
    }
  }

  export function* watchLoginCompany() {
    yield takeEvery(LOGIN_COMPANY, loginWithEmailPasswordCompany);
  }
  
  const loginWithEmailPasswordAsyncCompany = async (email, password) =>
    await auth
      .signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        try{
          const resp = await fetchSinToken('auth/enterprise', {email, password},'POST') 
          const body = await resp.json();
            if( body.ok ) {
              localStorage.setItem('token', body.token );
              localStorage.setItem('token-init-date', new Date().getTime() );
              return body
          } else {
            window.alert( body.msg );
          }
        } catch (error) {
          window.alert( error );
        }
    })
      .catch((error) => error);
  
  function* loginWithEmailPasswordCompany({ payload }) {
      const { email, password } = payload.company;
      const { history } = payload;
      try {
        const loginUser = yield call(loginWithEmailPasswordAsyncCompany, email, password);
        if ( !loginUser?.message && loginUser?.uid) {
          const item = { uid: loginUser.uid, displayName: loginUser.name, role: loginUser.role, verifiedByRcruitech:loginUser.verifiedByRcruitech /* ...currentUser */};
          if(!item?.verifiedByRcruitech) {
            yield put(showNotification(NotificationTypes.ERROR,
               'ERROR EN EL LOGIN', 
               'Su cuenta de empresa no está autorizada', 5000));
           return;
          }
          setCurrentUser(item);
          yield put(loginUserSuccess(item));
          
          setLoginData(email,password,true);
          if(history)
          {history.push(`${adminRoot}/enterprise/ofertas`)}
        } else {
          yield put(loginUserError(loginUser?.message));
        }
      } catch (error) {
        yield put(loginUserError(error));
      }
    }

export function* watchLoginUserFacebook() {
  yield takeEvery(LOGIN_USER_FACEBOOK, loginWithFacebook);
};

const sigInWithFacebook = async () =>
  await firebase
    .auth()
    .signInWithPopup(facebookAuthProvider)
    .then(async (u) => {
	/*
	    const { user } = u;
	    const { displayName, email, uid } = user;
	    */

      const { user, additionalUserInfo } = u;
      const { email } = additionalUserInfo.profile
      const { displayName, uid } = user;

      let role = {
        roleType: UserRole.Candidate,
        connection: 'facebook',
        firebaseId: uid,
      };
      try {
        const resp = await fetchSinToken(
          'auth/login/social',
          { displayName, email, role },
          'POST'
        );
        const body = await resp.json();
        if (body.ok) {
          localStorage.setItem('token', body.token);
          localStorage.setItem('token-init-date', new Date().getTime());
          setLoginData(email, "", role===UserRole.Enterprise);
          return body;
        }
      } catch (error) {
        window.alert(error);
      }
    })
    .catch((err) => console.log(err));
  
    function* loginWithFacebook({ payload }) {
      const history = payload;
      try {
        const loginUser = yield call(sigInWithFacebook);
        if ( loginUser&&!loginUser?.message) {
          const item = {
            uid: loginUser.uid,
            displayName: loginUser.name,
            role: loginUser.role /* ...currentUser */,
          };       
          setCurrentUser(item);
          yield put(loginUserSuccess(item));
          if (history) {
            history.push(`${adminRoot}/user/ofertas`);
          }
        } else {
          yield put(loginUserError(loginUser?.message||""));
        }
      } catch (error) {
        yield put(loginUserError(error));
      }
    }
    const loginSocialLinkedInRequest = async ({ payload }) => {
      try {
        const list = await fetchSinToken(
          `auth/login/linkedIn`,
          { loginData: { ...payload } },
          'POST'
        );
        const body = await list.json();
    
        if (body.ok) {
          const { msg } = body;
          return msg;
        } else {
          if (body.userExists) {
            const errorMessage = {msg:body.msg, userExists:true}
            throw errorMessage;
          }
          else
         { throw Error('Error in processing request');}
        }
      } catch (error) {
        throw error;
      }
    };

    function* loginSocialLinkedIn(payload) {
      try {
        const response = yield call(loginSocialLinkedInRequest, payload);
        yield put(loginLinkedInSuccess(response));
      } catch (error) {
        if (error?.userExists) {
          yield put(
            showNotification(
              NotificationTypes.ERROR,
              'REGISTRO INCORRECTO',
              'El usuario ya estáregistrado por email o Facebook',
              3600000
            )
          );
        }
        yield put(loginLinkedInError(error));
      }
    }
    
const refreshLoginSocialRequest = async ({ payload }) => {
  const { email } = payload.user;
  try {
    const list = await fetchConToken(
      `auth/login/social/linkedIn`,
      { email },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* refreshLoginSocial(payload) {
  try {
    const loginUser = yield call(refreshLoginSocialRequest, payload);
    if (!loginUser.message) {
      const item = {
        uid: loginUser.uid,
        displayName: loginUser.name,
        role: loginUser.role,
      };
      localStorage.setItem('token', loginUser.token);
      localStorage.setItem('token-init-date', new Date().getTime());
      setCurrentUser(item);
      setLoginData(loginUser.email, '', item.role === UserRole.Enterprise);
      yield put(loginUserSuccess(item));


    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}



export function* watchLoginLinkedIn() {
  yield takeEvery(LOGIN_SOCIAL_LINKEDIN, loginSocialLinkedIn);
}



export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}


export function* watchLoginSocial() {
  yield takeEvery(LOGIN_SOCIAL, refreshLoginSocial);
}

const registerWithEmailPasswordAsync = async (email, password, name) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then( async ({user}) => {
      let { uid } = user
      let role = {roleType:UserRole.Candidate, connection:'firebase', firebaseId: uid}
          try {
            const resp = await fetchSinToken( 'auth/new', { name, email, password, role }, 'POST' );
            const body = await resp.json();
            await user.sendEmailVerification();

            if( body.ok ) {
                localStorage.setItem('token', body.token );
                localStorage.setItem('token-init-date', new Date().getTime() );
                
                await user.updateProfile({ displayName: name });
                return user
            } else {
                return window.alert( body.msg);
            }

        } catch (error) {
            window.alert( error );
    }
    })
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password, name } = payload.user;
  const { history } = payload;
  //console.log(`Payload del registro `, payload, history);
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password, 
      name
    );
      if (!registerUser.message) {
        const item = { uid: registerUser.uid, displayName: registerUser.displayName, role: UserRole.user, ...currentUser };
        setCurrentUser(item);
        yield put(registerUserSuccess(item));
        setLoginData(email,password,false);
        //console.log("Antes del push del registro: ", history);
        if(history){ history.push({pathname:`/user/login`, state:{showCandidateVerficationMessage:true}});}
      } else {
        yield put(registerUserError(registerUser.message))
      }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterCompany() {
  yield takeEvery(REGISTER_COMPANY, registerWithEmailPasswordCompany);
}

const registerWithEmailPasswordAsyncCompany = async (
  email, 
  password, 
  name,
  surname,
  companyName,
  phoneNumber,
  cif,
  dniUsuario,
  domicilioSocial,
  razonSocial
  ) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then( async ({user}) => {
      let { uid } = user
      let role = {roleType:UserRole.Enterprise, connection:'firebase', firebaseId: uid}
      
          try {
            const resp = await fetchSinToken( 'auth/new/enterprise', { 
              name, 
              surname, 
              email, 
              password, 
              cif,
              companyName,
              phoneNumber,
              dniUsuario,
              domicilioSocial, 
              razonSocial,
              role }
              , 'POST' );
            const body = await resp.json();
            await user.sendEmailVerification();

            if( body.ok ) {
                localStorage.setItem('token', body.token );
                localStorage.setItem('token-init-date', new Date().getTime() );
                
                await user.updateProfile({ displayName: name });
                return user
            } else {
                return window.alert( body.msg);
            }

        } catch (error) {
            window.alert( error );
    }
    })
    .catch((error) => error);

function* registerWithEmailPasswordCompany({ payload }) {
  const { 
    email, 
    password, 
    name, 
    surname,
    cif,
    companyName,
    phoneNumber,
    dniUsuario,
    domicilioSocial,
    razonSocial

    } = payload.company;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsyncCompany,
      email,
      password, 
      name, surname, companyName, phoneNumber, cif, dniUsuario, domicilioSocial,razonSocial
    );
      if (registerUser&&!registerUser?.message) {
        // const item = { uid: registerUser.uid, displayName: registerUser.displayName, role: UserRole.Admin, ...currentUser };
        yield put(registerUserSuccess(null));
        if(history){ history.push({pathname:`/enterprise/login`, state:{showEnterpriseVerficationMessage:true}});}
      } else {
        yield put(registerUserError(registerUser?.message))
      }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history, isCompany) => {

  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
    if(isCompany){
      history.push(`${adminRoot}/enterprise/login`)
    }
    else  history.push(`${adminRoot}/user/login`)
};

function* logout({ payload }) {
  const { history } = payload;
  const isUserCompany =isCompany();
  setCurrentUser();
  yield call(logoutAsync, history, isUserCompany);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginUserFacebook),
    fork(watchLoginLinkedIn),
    fork(watchLoginSocial),
    fork(watchLoginCompany),
    fork(watchRegisterCompany),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
