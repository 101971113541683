import { NotificationTypes } from '../../constants/UIConstants';
import { CLEAR_NOTIFICATION, SHOW_NOTIFICATION } from '../actions';
export const showNotification = (
  type = NotificationTypes.INFO,
  title = '',
  message = '',
  timeout = 3000
) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: { type, title, message, timeout },
  };
};
export const clearNotification = () => {
  return {
    type: CLEAR_NOTIFICATION,
  };
};
