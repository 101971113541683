export const OfertasEmpresasDetalleTab = {
  details: 'details',
  candidatos: 'candidatos',
  candidatosApuntados: 'onhold',
};

export const CandidateProfileTab = {
  perfil: 'perfil',
  candidaturas: 'candidaturas',
};

export const NotificationTypes = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};

export const JobCategories = ['FullStack', 'FrontEnd', 'BackEnd', 'Otros'];
