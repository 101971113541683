import {
    OFERTAS_APPLY, 
    OFERTAS_APPLY_ERROR, 
    OFERTAS_APPLY_SUCCESS,
    OFERTAS_LIST_USER,
    OFERTAS_LIST_USER_SUCCESS,
    OFERTAS_LIST_USER_ERROR,
    OFERTAS_CREAR,
    OFERTAS_CREAR_SUCCESS,
    OFERTAS_CREAR_ERROR,
    OFERTAS_BORRAR,
    OFERTAS_BORRAR_SUCCESS,
    OFERTAS_BORRAR_ERROR,
  } from '../actions';
  
  export const addCandidate = (payload) => ({
    type: OFERTAS_APPLY,
    payload: payload
  });

  export const addCandidateToOfferSuccess = (items) => ({
    type: OFERTAS_APPLY_SUCCESS,
    payload: items,
  });
  
  export const addCandidateToOfferError = (error) => ({
    type: OFERTAS_APPLY_ERROR,
    payload: error,
  });

  export const getCandidate = (payload) => ({
    type: OFERTAS_LIST_USER,
    payload:payload
  });

  export const getCandidateSuccess = (items) => ({
    type: OFERTAS_LIST_USER_SUCCESS,
    payload: items,
  });
  
  export const getCandidateError = (error) => ({
    type: OFERTAS_LIST_USER_ERROR,
    payload: error,
  });

  export const createOffer = (item) => ({
    type: OFERTAS_CREAR,
    payload: item
  });

  export const createOfferSuccess = (items) => ({
    type: OFERTAS_CREAR_SUCCESS,
    payload: items,
  });
  
  export const createOfferError = (error) => ({
    type: OFERTAS_CREAR_ERROR,
    payload: error,
  });

  export const deleteOffer = (idOffer, history) => ({
    type: OFERTAS_BORRAR,
    payload: {idOffer, history}
  });

  export const deleteOfferSuccess = (items) => ({
    type: OFERTAS_BORRAR_SUCCESS,
    payload: items,
  });
  
  export const deleteOfferError = (error) => ({
    type: OFERTAS_BORRAR_ERROR,
    payload: error,
  });
