import { CLEAR_NOTIFICATION, SHOW_NOTIFICATION } from '../actions';

const INTITIAL_STATE = {
  notificationObj: null,
  notificationId: 0,
};
export default (state = INTITIAL_STATE, action) => {
  switch (action?.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notificationId: new Date().getTime(),
        notificationObj: action.payload,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notificationObj: null,
      };
    default:
      return { ...state };
  }
};
