import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchConToken } from '../../helpers/fetch';

import { 
    PROFILE_GET_USER,
    PROFILE_GET_ENTERPRISE,
    PROFILE_ADD_USER,
     PROFILE_GET_IMAGE } from '../actions';

import {
  getProfileUserSuccess,
  getProfileUserError,
  updateProfileUserSuccess,
  updateProfileUserError,
  getProfileEnterpriseSuccess,
  getProfileEnterpriseError,
  getProfileImageUserSuccess,
  getProfileImageUserErrror,
} from './actions';

//import todoData from '../../data/todos.json';


export function* watchGetUser() {
  yield takeEvery(PROFILE_GET_USER, getProfileUserSocial);
}

const getProfileUserAsync = async (uid) => {

  try {
    const resp = await fetchConToken('profiles/me', {uid}, 'GET');
    const body = await resp.json()
    
      if ( body.ok ) {
          return body
      } else {
        //console.log("Error al parsear el valor de vuelta");
        throw Error('An error occurred')
      }
    
  } catch (error) {
    //console.log("Error del valor del servidor: ", error);
    throw Error('An error occurred')
  }

  
}

function* getProfileUserSocial ({payload}) {

    const uid = payload;
    try {
        const {profile} = yield call(
            getProfileUserAsync,
            uid,
          );
          yield put(getProfileUserSuccess(profile));
    } catch (err) {
        yield put(getProfileUserError(err));
    }
}

 export function* watchGetEnterprise() {
    yield takeEvery(PROFILE_GET_ENTERPRISE, getProfileEnterpriseSocial);
  }

 const getProfileEnterpriserAsync = async (uid) => {

  
    try {
      const resp = await fetchConToken('profiles/enterprise/me', {uid}, 'GET');
      const body = await resp.json()
      
        if ( body.ok ) {
            return body
        } else {
        //  console.log("Error al parsear el valor de vuelta de empresas");
        throw Error('An error occurred')
        }
      
    } catch (error) {
      //console.log("Error del valor del servidor de empresas: ", error);
      throw Error('An error occurred')
    }
  
    
  }
  
  function* getProfileEnterpriseSocial ({payload}) {
      const uid = payload;
  
      try {
          const {profile} = yield call(
              getProfileEnterpriserAsync,
              uid,
            );
            yield put(getProfileEnterpriseSuccess(profile));
      } catch (err) {
          yield put(getProfileEnterpriseError(err));
      }
  }

export function* watchUpdateUser() {
  yield takeEvery(PROFILE_ADD_USER, addProfileUser);
}

const addProfileUserAsync = async (payload) => {

    try {
      const resp = await fetchConToken('profiles/me/update', payload, 'POST');
      const body = await resp.json()
      
        if ( body.ok ) {
            return body
        } else {
        //  console.log("Error al parsear el valor de vuelta");
        throw Error('An error occurred')
        }
      
    } catch (error) {
      //console.log("Error del valor del servidor: ", error);
      throw Error('An error occurred')
    }
}

function* addProfileUser ({payload}) { 

      try {
        const {profile} = yield call(
            addProfileUserAsync,
            payload,
          );
          yield put(updateProfileUserSuccess(profile));
    } catch (err) {
        yield put(updateProfileUserError(err));
    }

};


export function* watchGetAvatar() {
    yield takeEvery(PROFILE_GET_IMAGE, getAvatarProfileUser);
}

const getAvatarProfileUserAsync = async () => {

   try {
     const resp = await fetchConToken('profiles/me/avatar', 'GET');
     const body = await resp.json()
     
       if ( body.ok ) {
           return body
       } else {
         //console.log("Error al parsear el valor de vuelta");
         throw Error('An error occurred')
       }
     
   } catch (error) {
     //console.log("Error del valor del servidor: ", error);
     throw Error('An error occurred')
   }
}

function* getAvatarProfileUser () { 
      try {
        const image = yield call(
            getAvatarProfileUserAsync,
          );
          yield put(getProfileImageUserSuccess(image));
    } catch (err) {
        yield put(getProfileImageUserErrror(err));
    }
    
     
};

/* export function* watchAddEnterprise() {
    yield takeEvery(PROFILE_ADD_ENTERPRISE, addProfileEnterprise);
} */

export default function* rootSaga() {
  yield all([
    fork(watchGetUser), 
    /* fork(watchGetEnterprise), */
    fork(watchUpdateUser), 
    fork(watchGetAvatar),
/*     fork(watchAddAvatar),
    fork(watchAddPdf), */
    //fork(watchAddEnterprise),
]);
}
