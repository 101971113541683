import {
    PROFILE_GET_USER,
    PROFILE_GET_ENTERPRISE,
    PROFILE_GET_USER_SUCCESS,
    PROFILE_GET_ENTERPRISE_SUCCESS,
    PROFILE_GET_USER_ERROR,
    PROFILE_GET_ENTERPRISE_ERROR,
    PROFILE_ADD_USER,
    PROFILE_ADD_USER_SUCCESS,
    PROFILE_ADD_ENTERPRISE,
    PROFILE_ADD_ENTERPRISE_SUCCESS,
    PROFILE_ADD_USER_ERROR,
    PROFILE_ADD_ENTERPRISE_ERROR,
    PROFILE_GET_IMAGE,
    PROFILE_GET_IMAGE_SUCCESS,
    PROFILE_GET_IMAGE_ERROR,
    PROFILE_ADD_IMAGE,
    PROFILE_ADD_IMAGE_SUCCESS,
    PROFILE_ADD_IMAGE_ERROR,
    PROFILE_ADD_PDF,
    PROFILE_ADD_PDF_SUCCESS,
    PROFILE_ADD_PDF_ERROR,
  } from '../actions';
  
  export const getProfileUser = (uid) => ({
    type: PROFILE_GET_USER,
    payload: uid
  });

  export const getProfileEnterprise = () => ({
    type: PROFILE_GET_ENTERPRISE,
  });
  
  export const getProfileUserSuccess = (items) =>{ 
  return {
    type: PROFILE_GET_USER_SUCCESS,
    payload: items,
  };
}

  export const getProfileEnterpriseSuccess = (items) => ({
    type: PROFILE_GET_ENTERPRISE_SUCCESS,
    payload: items,
  });
  
  export const getProfileUserError = (error) => {

    return{
    type: PROFILE_GET_USER_ERROR,
    payload: error,}
  };
  
  export const getProfileEnterpriseError = (error) => ({
    type: PROFILE_GET_ENTERPRISE_ERROR,
    payload: error,
  });

  export const updateProfileUser = (item) => {
    return{
    type: PROFILE_ADD_USER,
    payload: item,}
  };
  
  export const updateProfileEnterprise = (items) => ({
    type: PROFILE_ADD_ENTERPRISE,
    payload: items,
  });

  export const updateProfileUserSuccess = (item) => ({
    type: PROFILE_ADD_USER_SUCCESS,
    payload: item
  })
  
  export const updateProfileEnterpriseSuccess = (item) => ({
    type: PROFILE_ADD_ENTERPRISE_SUCCESS,
    payload: item
  })
  
  export const updateProfileUserError = (error) => ({
    type: PROFILE_ADD_USER_ERROR,
    payload: error,
  }); 

  export const updateProfileEnterpriseError = (error) => ({
    type: PROFILE_ADD_ENTERPRISE_ERROR,
    payload: error,
  });
  
  export const getProfileImageUser = () => ({
    type: PROFILE_GET_IMAGE,
  });

  export const getProfileImageUserSuccess = (item) => ({
    type: PROFILE_GET_IMAGE_SUCCESS,
    payload: item
  });

  export const getProfileImageUserErrror = (error) => ({
    type: PROFILE_GET_IMAGE_ERROR,
    payload: error
  });

  export const addProfileImageUser = (image) => ({
    type: PROFILE_ADD_IMAGE,
    payload: image
  });

  export const addProfileImageUserSuccess = (item) => ({
    type: PROFILE_ADD_IMAGE_SUCCESS,
    payload: item
  });

  export const addProfileImageUserErrror = (error) => ({
    type: PROFILE_ADD_IMAGE_ERROR,
    payload: error
  });
  
  export const addProfilePdfUser = (pdf) => ({
    type: PROFILE_ADD_PDF,
    payload: pdf
  });

  export const addProfilePdfUserSuccess = (item) => ({
    type: PROFILE_ADD_PDF_SUCCESS,
    payload: item
  });

  export const addProfilePdfUserError = (error) => ({
    type: PROFILE_ADD_PDF_ERROR,
    payload: error
  });

  
  
  
  