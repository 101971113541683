
import React from 'react';
import {
    Route,
    Redirect,
} from 'react-router-dom';
import { getCurrentUser } from './Utils'
import { isAuthGuardActive } from '../constants/defaultValues';

const ProtectedRoute = ({ component: Component, roles = undefined, ...rest }) => {
    const setComponent = (props) => {
        if (isAuthGuardActive) {
            const currentUser = getCurrentUser();
            const { location } = rest
            if (!!currentUser) {
                if (roles) {
                    if (roles.includes(currentUser.role)) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect
                            to={{
                                pathname: '/unauthorized',
                                state: { from: props.location },
                            }} />
                    }
                } else {
                    return <Component {...props} />;
                }
            } else if (location.pathname.includes('enterprise')){
                    return <Redirect
                        to={{
                            pathname: '/enterprise/login',
                            state: { from: props.location },
                        }} />
                    }
                    else if(location.pathname.includes('publicas')){
                        return <Component {...props} /> 
                    } else if (location.pathname.includes('app')){
                            return <Redirect
                            to={{
                                pathname: '/user/login',
                                state: { from: props.location },
                            }} />
                    }
                    else  if(location.pathname.includes('ofertas')){ 
                         return <Redirect
                            to={{
                                pathname: '/app/ofertas',
                                state: { from: props.location },
                           }} /> 
                    }
        } else {
            return <Component {...props} />;
        }

    }

    return (
        <Route
            {...rest}
            render={setComponent}
        />
    );
}
const UserRole = {
    Admin: 0,
    Candidate: 1,
    Enterprise: 2,
    anonymous: 3
}

export { ProtectedRoute, UserRole };
