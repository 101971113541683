/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import profileSagas from './profile/saga'
import ofertasSagas  from './ofertas/saga'
import adminDataSagas  from './adminData/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    profileSagas(),
    ofertasSagas(),
    adminDataSagas(),
  ]);
}
