/* import { allOffers } from '../../constants/defaultValues';
import {
  offersList,
  allEnterpriseOffers,
  candidatesList,
  enterpriseList,
  candidatesDetails,
} from '../../constants/OfertasList'; */
import {
  DATA_GET_ALL_ENTERPRISES,
  DATA_GET_ALL_ENTERPRISES_SUCCESS,
  DATA_GET_ALL_ENTERPRISES_ERROR,
  DATA_GET_ALL_CANDIDATES,
  DATA_GET_ALL_CANDIDATES_SUCCESS,
  DATA_GET_ALL_CANDIDATES_ERROR,
  DATA_GET_CANDIDATE_DETAIL,
  DATA_GET_CANDIDATE_DETAIL_SUCCESS,
  DATA_GET_CANDIDATE_DETAIL_ERROR,
  DATA_GET_OFFER_DETAIL,
  DATA_GET_OFFER_DETAIL_SUCCESS,
  DATA_GET_OFFER_DETAIL_ERROR,
  DATA_GET_ALL_OFFERS,
  DATA_GET_ALL_OFFERS_SUCCESS,
  DATA_GET_ALL_OFFERS_ERROR,
  DATA_SET_OFFER_STATUS,
  DATA_SET_OFFER_STATUS_SUCCESS,
  DATA_SET_OFFER_STATUS_ERROR,
  DATA_SET_CANDIDATE_STATUS,
  DATA_SET_CANDIDATE_STATUS_SUCCESS,
  DATA_SET_CANDIDATE_STATUS_ERROR,
  DATA_FILTER_CANDIDATES,
  DATA_FILTER_CANDIDATES_SUCCESS,
  DATA_FILTER_CANDIDATES_ERROR,
  DATA_FILTER_OFFERS,
  DATA_FILTER_OFFERS_SUCCESS,
  DATA_FILTER_OFFERS_ERROR,
  DATA_FILTER_ENTERPRISES,
  DATA_FILTER_ENTERPRISES_SUCCESS,
  DATA_FILTER_ENTERPRISES_ERROR,
  DATA_GET_ENTERPRISE_DETAIL_ERROR,
  DATA_GET_ENTERPRISE_DETAIL_SUCCESS,
  DATA_GET_ENTERPRISE_DETAIL,
  DATA_GET_OFFER_CANDIDATE_DETAIL,
  DATA_GET_OFFER_CANDIDATE_DETAIL_SUCCESS,
  DATA_GET_OFFER_CANDIDATE_DETAIL_ERROR,
  DATA_CLEAR_ALL_CANDIDATES,
  DATA_UPDATE_CANDIDATE_PROFILE,
  DATA_UPDATE_CANDIDATE_PROFILE_SUCCESS,
  DATA_UPDATE_CANDIDATE_PROFILE_ERROR,
  DATA_UPDATE_ENTERPRISE_PROFILE,
  DATA_UPDATE_ENTERPRISE_PROFILE_SUCCESS,
  DATA_UPDATE_ENTERPRISE_PROFILE_ERROR,
  DATA_DELETE_OFFER,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS_SUCCESS,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS_ERROR,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES,
  DATA_GET_ALL_PROCESSED_CANDIDATES_ERROR,
  DATA_GET_ALL_PROCESSED_CANDIDATES_SUCCESS,
  DATA_GET_ALL_PROCESSED_CANDIDATES,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES_SUCCESS,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES_ERROR,
  DATA_UPDATE_CANDIDATOS_APUNTADOS_FILTER,
  DATA_CLEAR_CANDIDATOS_APUNTADOS_FILTER,
  DATA_UPDATE_CANDIDATOS_FILTER,
  DATA_CLEAR_CANDIDATOS_FILTER,
  DATA_UPDATE_ENTERPRISE_STATUS,
  DATA_UPDATE_ENTERPRISE_STATUS_SUCCESS,
  DATA_UPDATE_ENTERPRISE_STATUS_ERROR,
} from '../actions';

const INIT_STATE = {
  allEnterprises: [],
  allCandidates: [],
  filteredCandidatosApuntados: [],
  filteredCandidatos: [],
  filterObjCandidatosApuntados: null,
  filterObjCandidatos: null,
  allOffers: [],
  error: '',
  filterOffers: null,
  filterCandidates: '',
  filterEnterprises: null,
  loading: false,
  settingCandidateStatus: false,
  settingEnterpriseStatus: false,
  loadingCandidateProfile: false,
  loadingFilteredCandidatos: false,
  loadingFilteredCandidatosApuntados: false,
  offerDetail: {},
  candidateProfile: {},
  enterpriseProfile: {},
  currentJobStatus: '',
};

const addToAllOffers = (originalList, list) => {
  if (!list || !list.length) return originalList;
  let updatedOriginalList = originalList.map((el) => ({ ...el }));

  list.forEach((el) => {
    const currIndex = originalList.findIndex(
      (orig) => orig.idOffer === el.idOffer
    );
    if (currIndex < 0) updatedOriginalList.push(el);
    else updatedOriginalList[currIndex] = el;
  });
  return updatedOriginalList.slice();
};

const updateAllEnterprises = (originalList, enterprise) => {
  if (!originalList || !originalList?.length || !enterprise)
    return originalList;
  let updatedList = originalList.map((el) => ({ ...el }));
  let currIndex = updatedList.findIndex((el) => el?._id === enterprise?.id);
  if (currIndex < 0) updatedList.push(originalList);
  else updatedList[currIndex] = enterprise;
  return updatedList;
};

const deletOffer = (originalList, id) => {
  let updatedOriginalList = originalList.map((el) => ({ ...el }));
  const currIndex = updatedOriginalList.findIndex((el) => el.idOffer === id);
  updatedOriginalList.splice(currIndex, 1);
  return updatedOriginalList;
};

const getInitCandidatosApuntados = (state, possibleValue) => {
  let conditionalClearCandidatosApuntados = {};
  if (!state.filterObjCandidatosApuntados) {
    conditionalClearCandidatosApuntados = {
      filteredCandidatosApuntados: possibleValue,
    };
  }
  return conditionalClearCandidatosApuntados;
};

const getInitCandidatos = (state, possibleValue) => {
  let conditionalClearCandidatos = {};
  if (!state.filterObjCandidatos) {
    conditionalClearCandidatos = {
      filteredCandidatos: possibleValue,
    };
  }
  return conditionalClearCandidatos;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DATA_GET_ALL_ENTERPRISES:
      return { ...state, loading: true, error: '' };

    case DATA_GET_ALL_ENTERPRISES_SUCCESS:
      return {
        ...state,
        loading: false,
        allEnterprises: action.payload,
      };

    case DATA_GET_ALL_ENTERPRISES_ERROR:
      return { ...state, loading: false, error: action.payload };

    /* case SURVEY_LIST_GET_LIST_PUBLIC:
        return { ...state, loading: false }; */

    case DATA_CLEAR_ALL_CANDIDATES:


      return {
        ...state,
        loading: false,
        error: '',
        allCandidates: [],
        ...getInitCandidatos(state, []),
        ...getInitCandidatosApuntados(state, []),
      };

    case DATA_UPDATE_CANDIDATOS_FILTER:
      return {
        ...state,
        filterObjCandidatos: action.payload,
      };
    case DATA_CLEAR_CANDIDATOS_FILTER:
      return {
        ...state,
        filterObjCandidatos: null,
      };

    case DATA_UPDATE_CANDIDATOS_APUNTADOS_FILTER:
      return {
        ...state,
        filterObjCandidatosApuntados: action.payload,
      };
    case DATA_CLEAR_CANDIDATOS_APUNTADOS_FILTER:
      return {
        ...state,
        filterObjCandidatosApuntados: null,
      };

    case DATA_GET_ALL_CANDIDATES:
      return {
        ...state,
        loading: true,
        loadingFilteredCandidatos: true,
        loadingFilteredCandidatosApuntados: true,
        error: '',
        allCandidates: [],
        ...getInitCandidatos(state, []),
        ...getInitCandidatosApuntados(state, []),
      };

    case DATA_GET_ALL_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingFilteredCandidatos: false,
        loadingFilteredCandidatosApuntados: false,
        allCandidates: action.payload,
        ...getInitCandidatos(state, action.payload),
        ...getInitCandidatosApuntados(state, action.payload),
      };

    case DATA_GET_ALL_CANDIDATES_ERROR:
      return {
        ...state,
        loading: false,
        loadingFilteredCandidatos: false,
        loadingFilteredCandidatosApuntados: false,
        error: action.payload,
      };

    case DATA_SET_CANDIDATE_STATUS:
      return {
        ...state,
        loading: true,
        settingCandidateStatus: true,
        error: '',
      };

    case DATA_SET_CANDIDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        settingCandidateStatus: false,
        offerDetail: action.payload,
      };

    case DATA_SET_CANDIDATE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        settingCandidateStatus: false,
        error: action.payload,
      };

    case DATA_UPDATE_ENTERPRISE_STATUS:
      return {
        ...state,
        loading: true,
        settingEnterpriseStatus: true,
        error: '',
      };

    case DATA_UPDATE_ENTERPRISE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        settingEnterpriseStatus: false,
        enterpriseProfile: action.payload,
        allEnterprises: updateAllEnterprises(
          state.allEnterprises,
          action.payload
        ),
      };

    case DATA_UPDATE_ENTERPRISE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        settingEnterpriseStatus: false,
        error: action.payload,
      };

    case DATA_SET_OFFER_STATUS:
      return { ...state, loading: true, error: '' };

    case DATA_SET_OFFER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        offerDetail: action.payload,
        allOffers: addToAllOffers(state.allOffers, action.payload),
      };

    case DATA_SET_OFFER_STATUS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_GET_ALL_OFFERS:
      return { ...state, loading: true, error: '' };

    case DATA_GET_ALL_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOffers: addToAllOffers(state.allOffers, action.payload),
      };

    case DATA_GET_ALL_OFFERS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_DELETE_OFFER:
      return {
        ...state,
        loading: false,
        allOffers: deletOffer(state.allOffers, action.payload),
      };

    case DATA_GET_CANDIDATE_DETAIL:
      return {
        ...state,
        loading: true,
        loadingCandidateProfile: true,
        error: '',
        candidateProfile: {},
      };

    case DATA_GET_CANDIDATE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingCandidateProfile: false,
        candidateProfile: action.payload,
      };

    case DATA_GET_CANDIDATE_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        loadingCandidateProfile: false,
        error: action.payload,
      };

    case DATA_UPDATE_CANDIDATE_PROFILE:
      return {
        ...state,
        loading: true,
        error: '',
        candidateProfile: {},
      };

    case DATA_UPDATE_CANDIDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, candidateProfile: action.payload };

    case DATA_UPDATE_CANDIDATE_PROFILE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_UPDATE_CANDIDATE_CANDIDATURAS:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case DATA_UPDATE_CANDIDATE_CANDIDATURAS_SUCCESS:
      return {
        ...state,
        loading: false,
        candidateProfile: {
          ...state.candidateProfile,
          candidaturas: action.payload,
        },
      };

    case DATA_UPDATE_CANDIDATE_CANDIDATURAS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_UPDATE_ENTERPRISE_PROFILE:
      return {
        ...state,
        loading: true,
        error: '',
        enterpriseProfile: {},
      };

    case DATA_UPDATE_ENTERPRISE_PROFILE_SUCCESS:
      return { ...state, loading: false, enterpriseProfile: action.payload };

    case DATA_UPDATE_ENTERPRISE_PROFILE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_GET_OFFER_CANDIDATE_DETAIL:
      return {
        ...state,
        loading: true,
        loadingCandidateProfile: true,
        error: '',
        candidateProfile: {},
      };

    case DATA_GET_OFFER_CANDIDATE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingCandidateProfile: false,
        candidateProfile: action.payload,
      };

    case DATA_GET_OFFER_CANDIDATE_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        loadingCandidateProfile: false,
        error: action.payload,
      };

    case DATA_GET_ALL_PROCESSED_CANDIDATES:
      return {
        ...state,
        loading: true,
        loadingFilteredCandidatos: true,
        error: '',
        filteredCandidatos: [],
      };

    case DATA_GET_ALL_PROCESSED_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingFilteredCandidatos: false,
        filteredCandidatos: action.payload,
      };

    case DATA_GET_ALL_PROCESSED_CANDIDATES_ERROR:
      return {
        ...state,
        loading: false,
        loadingFilteredCandidatos: false,
        error: action.payload,
      };

    case DATA_GET_ALL_UNPROCESSED_CANDIDATES:
      return {
        ...state,
        loading: true,
        loadingFilteredCandidatosApuntados: true,
        error: '',
        filteredCandidatosApuntados: [],
      };

    case DATA_GET_ALL_UNPROCESSED_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingFilteredCandidatosApuntados: false,
        filteredCandidatosApuntados: action.payload,
      };

    case DATA_GET_ALL_UNPROCESSED_CANDIDATES_ERROR:
      return {
        ...state,
        loading: false,
        loadingFilteredCandidatosApuntados: false,
        error: action.payload,
      };

    case DATA_GET_ENTERPRISE_DETAIL:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case DATA_GET_ENTERPRISE_DETAIL_SUCCESS:
      return { ...state, loading: false, enterpriseProfile: action.payload };

    case DATA_GET_ENTERPRISE_DETAIL_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_GET_OFFER_DETAIL:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case DATA_GET_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        offerDetail: action.payload,
      };

    case DATA_GET_OFFER_DETAIL_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_FILTER_CANDIDATES:
      return {
        ...state,
        loading: true,
        error: '',
        filterCandidates: action.payload,
      };

    case DATA_FILTER_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        allCandidates: action.payload,
      };

    case DATA_FILTER_CANDIDATES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_FILTER_OFFERS:
      return {
        ...state,
        loading: true,
        error: '',
        filterOffers: action.payload,
      };

    case DATA_FILTER_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOffers: action.payload,
      };

    case DATA_FILTER_OFFERS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DATA_FILTER_ENTERPRISES:
      return {
        ...state,
        loading: true,
        error: '',
        filterEnterprises: action.payload,
      };

    case DATA_FILTER_ENTERPRISES_SUCCESS:
      return {
        ...state,
        loading: false,
        allEnterprises: action.payload,
      };

    case DATA_FILTER_ENTERPRISES_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
