import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import profileUserApp from './profile/reducer';
import ofertasApp from './ofertas/reducer';
import adminData from './adminData/reducer';
import notificationData from './notifications/reducers';
import {
  LOGIN_COMPANY_SUCCESS,
  LOGIN_SOCIAL_LINKEDIN_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_COMPANY_SUCCESS,
  REGISTER_USER_SUCCESS,
} from './actions';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  profileUserApp,
  ofertasApp,
  adminData,
  notificationData,
});
const rootReducers = (state, action) => {
  if (
    action.type === LOGIN_COMPANY_SUCCESS ||
    action.type === LOGIN_USER_SUCCESS ||
    action.type === LOGIN_SOCIAL_LINKEDIN_SUCCESS ||
    action.type === REGISTER_COMPANY_SUCCESS ||
    action.type === REGISTER_USER_SUCCESS ||
    action.type === LOGOUT_USER
  ) {
    state = undefined;
  }
  return reducers(state, action);
};
export default rootReducers;
