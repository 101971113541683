import {
  SURVEY_GET_DETAILS,
  SURVEY_GET_DETAILS_SUCCESS,
  SURVEY_GET_DETAILS_ERROR,
  SURVEY_DELETE_QUESTION,
  SURVEY_SAVE,
  SURVEY_UPDATE_DETAILS,
  SURVEY_UPDATE_DETAILS_SUCCESS,
  SURVEY_UPDATE_DETAILS_ERROR,
} from '../actions';

export const getSurveyDetail = (oferta) => ({
  type: SURVEY_GET_DETAILS,
  payload: oferta
});

export const getSurveyDetailSuccess = (items) => ({
  type: SURVEY_GET_DETAILS_SUCCESS,
  payload: items,
});

export const getSurveyDetailError = (error) => ({
  type: SURVEY_GET_DETAILS_ERROR,
  payload: error,
});

export const getUpdateSurveyDetails = (oferta) => ({
  type: SURVEY_UPDATE_DETAILS,
  payload: oferta
});

export const getUpdateSurveyDetailsSuccess = (items) => ({
  type: SURVEY_UPDATE_DETAILS_SUCCESS,
  payload: items,
});

export const getUpdateSurveyDetailsError = (error) => ({
  type: SURVEY_UPDATE_DETAILS_ERROR,
  payload: error,
});

export const deleteSurveyQuestion = (questionId, survey) => ({
  type: SURVEY_DELETE_QUESTION,
  payload: { questionId, survey },
});

export const saveSurvey = (survey) => ({
  type: SURVEY_SAVE,
  payload: survey,
});
