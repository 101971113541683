import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';

import Cryptr from 'cryptr';
import { UserRole } from './authHelper';
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const isValidValue = (val) => {
  return val != null && val !== undefined;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    //console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    //console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    //console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    //console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    /* console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    ); */
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    /* console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    ); */
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
   /*  console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    ); */
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    /* console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    ); */
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('rcruitech_current_user') != null
        ? JSON.parse(localStorage.getItem('rcruitech_current_user'))
        : null;
  } catch (error) {
    //console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user.isLogedIn = false;
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  //console.log('seteo user: ', user);
  try {
    if (user) {
      localStorage.setItem('rcruitech_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('rcruitech_current_user');
    }
  } catch (error) {
    //console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};
export const setLoginData = (email, password, isCompany) => {
  let encryptedPassword = encryptField(password);
  localStorage.setItem('password', encryptedPassword);
  localStorage.setItem('email', email);
  localStorage.setItem('isCompany', isCompany);
};
export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getTokenExpirationTime = () => {
  let token = localStorage.getItem('token');
  if (!token) return -1;
  let tokenInfo = parseJwt(token);
  let currentTime = new Date().getTime();
  let timeDiff = tokenInfo.exp * 1000 - currentTime;
  return timeDiff;
};
export const validateJwt = (callback) => {
  let token = localStorage.getItem('token');

  if (!token) {
    setCurrentUser(null);
    return;
  }
  let timeDiff = getTokenExpirationTime();
  if (timeDiff < 10000) setCurrentUser(null);
  return timeDiff > 10000;
};

export const encryptField = (value) => {
  if (!isValidValue(value)) return value;
  let cryptr = new Cryptr('59211e5f-d99c-4981-be36-d93866f941cc');
  return cryptr.encrypt(value);
};

export const decryptField = (value) => {
  if (!isValidValue(value)) return value;
  let cryptr = new Cryptr('59211e5f-d99c-4981-be36-d93866f941cc');
  return cryptr.decrypt(value);
};

export const isCompany = () => {
  let userStatus = localStorage.getItem('isCompany');
  return userStatus === true || userStatus === 'true';
};

export const hasPassword = () => {
  let encryptedPassword = localStorage.getItem('password');
  if (!isValidValue(encryptedPassword)) return false;
  let password = decryptField(encryptedPassword);
  return password && password?.trim()?.length > 0;
};

export const autoLoginHandler = (callback) => {
  let token = localStorage.getItem('token');
  if (!token) return;
  let tokenTimeDiff = getTokenExpirationTime();
  let isNotLatestTimeout = tokenTimeDiff > 15000;
  if (isNotLatestTimeout) return;
  let email = localStorage.getItem('email');
  let encryptedPassword = localStorage.getItem('password');
  //console.log(!email || !encryptedPassword);
  if (!email || (isCompany() && !encryptedPassword)) return;
  let password = decryptField(encryptedPassword || '');
  let data = {
    email,
    password,
  };
  callback(data);
};

export const joinPath = (base, path) => {
  return base.charAt(base.length - 1) === '/'
    ? base.slice(0, -1) + '/' + path
    : base + '/' + path;
};

export const hasNoProfile = (currentUser, profileUser) => {
  return (
    currentUser &&
    currentUser.role === UserRole.Candidate &&
    profileUser &&
    !profileUser?.studiesIT?.length
  );
};
