//import { ofertasList } from './OfertasList';
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const allCandidates = [
  { name: 'John', status: 'Hired' },
  { name: 'Shaekspear', status: 'Interview' },
  { name: 'Leonardo', status: 'Interview' },
  { name: 'Jack', status: 'Rejected' },
  { name: 'Sparrow', status: 'Hired' },
];

export const allEnterprises = [
  { name: 'John', status: 'Hired' },
  { name: 'Shaekspear', status: 'Interview' },
  { name: 'Leonardo', status: 'Interview' },
  { name: 'Jack', status: 'Rejected' },
  { name: 'Sparrow', status: 'Hired' },
];

//export const allOffers = ofertasList;

export const singleCandidate = [
  { name: 'John', status: 'Hired' },
  { name: 'Shaekspear', status: 'Interview' },
  { name: 'Leonardo', status: 'Interview' },
  { name: 'Jack', status: 'Rejected' },
  { name: 'Sparrow', status: 'Hired' },
];

/*
  export const firebaseConfig = {
  apiKey: 'AIzaSyBt2Gvth95JCNaV64gU-e4ygwEhFFVoDZw',
  authDomain: 'demov2-rcruitech.firebaseapp.com',
  databaseURL: 'https://demov2-rcruitech.firebaseio.com',
  projectId: 'demov2-rcruitech',
  storageBucket: 'demov2-rcruitech.appspot.com',
  messagingSenderId: '282516630092',
  appId: '1:282516630092:web:521e45a1d7502f7f3be2e9',
};
*/

export const firebaseConfig = {
  apiKey: "AIzaSyAo5S1jEFv_0fu1hpJqPVhvZw8lhBguwtc",
  authDomain: "rcruitech-a539e.firebaseapp.com",
  databaseURL: "https://rcruitech-a539e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rcruitech-a539e",
  storageBucket: "rcruitech-a539e.appspot.com",
  messagingSenderId: "755109769914",
  appId: "1:755109769914:web:5fbce8d82b528967698883",
  measurementId: "G-88H9DC5Q1J"
};


export const homeRoot = '/app/ofertas/publicas';
export const adminRoot = '/app';
export const userRoot = '/app/user';
export const enterpriseRoot = '/app/enterprise';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: '',
  title: '',
  img: '',
  date: '',
  role: 4,
};

export const mensajeCorreoEmpresas =
  'Se le ha enviado un correo electrónico a la dirección suministrada para que verifique su cuenta. Por favor acepte para poder loguearse';
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const isValidUser = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
