import {
  SURVEY_LIST_GET_LIST,
  SURVEY_LIST_GET_LIST_PUBLIC,
  SURVEY_LIST_GET_LIST_SUCCESS,
  SURVEY_LIST_GET_LIST_PUBLIC_SUCCESS,
  SURVEY_LIST_GET_LIST_ERROR,
  SURVEY_LIST_GET_LIST_PUBLIC_ERROR,
  SURVEY_LIST_GET_LIST_WITH_FILTER,
  SURVEY_LIST_GET_LIST_WITH_ORDER,
  SURVEY_LIST_GET_LIST_SEARCH,
  SURVEY_LIST_ADD_ITEM,
  SURVEY_LIST_ADD_ITEM_SUCCESS,
  SURVEY_LIST_ADD_ITEM_ERROR,
  SURVEY_LIST_SELECTED_ITEMS_CHANGE,
  SURVEY_LIST_GET_LIST_ANONYMOUS_PUBLIC,
  SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_SUCCESS,
  SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_ERROR,
  SURVEY_LIST_EDIT_ITEM,
  SURVEY_LIST_EDIT_ITEM_SUCCESS,
  SURVEY_LIST_EDIT_ITEM_ERROR,
} from '../actions';

export const getSurveyList = (payload={}) => ({
  type: SURVEY_LIST_GET_LIST,
  payload:payload
});

export const getSurveyPublicList = (payload) => ({
  type: SURVEY_LIST_GET_LIST_PUBLIC,
  payload:payload
});

export const getSurveyPublicAnonymousList = (filter={}) => ({
  type: SURVEY_LIST_GET_LIST_ANONYMOUS_PUBLIC,
  payload:filter
});

export const getSurveyListSuccess = (items) => {
  return({
        type: SURVEY_LIST_GET_LIST_SUCCESS,
        payload: items
      })
};

export const getSurveyListPublicSuccess = (items) => {
  return({
        type: SURVEY_LIST_GET_LIST_PUBLIC_SUCCESS,
        payload: items
      })
};

export const getSurveyListPublicAnonymousSuccess = (items) => {
  return({
        type: SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_SUCCESS,
        payload: items
      })
};



export const getSurveyListError = (error) => ({
  type: SURVEY_LIST_GET_LIST_ERROR,
  payload: error,
});

export const getSurveyListPublicError = (error) => ({
  type: SURVEY_LIST_GET_LIST_PUBLIC_ERROR,
  payload: error,
});

export const getSurveyListPublicAnonymousError = (error) => ({
  type: SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_ERROR,
  payload: error,
});

export const getSurveyListWithFilter = (column, value) => ({
  type: SURVEY_LIST_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getSurveyListWithOrder = (column) => ({
  type: SURVEY_LIST_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getSurveyListSearch = (keyword) => ({
  type: SURVEY_LIST_GET_LIST_SEARCH,
  payload: keyword,
});

export const addSurveyItem = (offer, history, redirectionLink) => ({
  type: SURVEY_LIST_ADD_ITEM,
  payload: {offer, history,redirectionLink},
});

export const addSurveyItemSuccess = (items) => ({
  type: SURVEY_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addSurveyItemError = (error) => ({
  type: SURVEY_LIST_ADD_ITEM_ERROR,
  payload: error,
});


export const editSurveyItem = (item) => ({
  type: SURVEY_LIST_EDIT_ITEM,
  payload: item,
});

export const editSurveyItemSuccess = (items) => ({
  type: SURVEY_LIST_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editSurveyItemError = (error) => ({
  type: SURVEY_LIST_EDIT_ITEM_ERROR,
  payload: error,
});

export const selectedSurveyItemsChange = (selectedItems) => ({
  type: SURVEY_LIST_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});
