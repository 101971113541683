import {
  DATA_GET_ALL_ENTERPRISES,
  DATA_GET_ALL_ENTERPRISES_SUCCESS,
  DATA_GET_ALL_ENTERPRISES_ERROR,
  DATA_GET_ALL_CANDIDATES,
  DATA_GET_ALL_CANDIDATES_SUCCESS,
  DATA_GET_ALL_CANDIDATES_ERROR,
  DATA_GET_ALL_OFFERS,
  DATA_GET_ALL_OFFERS_SUCCESS,
  DATA_GET_ALL_OFFERS_ERROR,
  DATA_GET_CANDIDATE_DETAIL,
  DATA_GET_CANDIDATE_DETAIL_SUCCESS,
  DATA_GET_CANDIDATE_DETAIL_ERROR,
  DATA_GET_OFFER_DETAIL,
  DATA_GET_OFFER_DETAIL_SUCCESS,
  DATA_GET_OFFER_DETAIL_ERROR,
  DATA_SET_OFFER_STATUS,
  DATA_SET_OFFER_STATUS_SUCCESS,
  DATA_SET_OFFER_STATUS_ERROR,
  DATA_ADD_CANDIDATE_TO_OFFER,
  DATA_ADD_CANDIDATE_TO_OFFER_SUCCESS,
  DATA_ADD_CANDIDATE_TO_OFFER_ERROR,
  DATA_SET_CANDIDATE_STATUS,
  DATA_SET_CANDIDATE_STATUS_SUCCESS,
  DATA_SET_CANDIDATE_STATUS_ERROR,
  DATA_FILTER_CANDIDATES,
  DATA_FILTER_CANDIDATES_SUCCESS,
  DATA_FILTER_CANDIDATES_ERROR,
  DATA_FILTER_OFFERS,
  DATA_FILTER_OFFERS_SUCCESS,
  DATA_FILTER_OFFERS_ERROR,
  DATA_FILTER_ENTERPRISES,
  DATA_FILTER_ENTERPRISES_SUCCESS,
  DATA_FILTER_ENTERPRISES_ERROR,
  DATA_GET_ENTERPRISE_DETAIL,
  DATA_GET_ENTERPRISE_DETAIL_SUCCESS,
  DATA_GET_ENTERPRISE_DETAIL_ERROR,
  DATA_GET_OFFER_CANDIDATE_DETAIL,
  DATA_GET_OFFER_CANDIDATE_DETAIL_SUCCESS,
  DATA_GET_OFFER_CANDIDATE_DETAIL_ERROR,
  DATA_GET_OFFER_CANDIDATE_STATUS,
  DATA_GET_OFFER_CANDIDATE_STATUS_SUCCESS,
  DATA_GET_OFFER_CANDIDATE_STATUS_ERROR,
  DATA_CLEAR_ALL_CANDIDATES,
  DATA_UPDATE_CANDIDATE_PROFILE,
  DATA_UPDATE_CANDIDATE_PROFILE_SUCCESS,
  DATA_UPDATE_CANDIDATE_PROFILE_ERROR,
  DATA_UPDATE_ENTERPRISE_PROFILE,
  DATA_UPDATE_ENTERPRISE_PROFILE_SUCCESS,
  DATA_UPDATE_ENTERPRISE_PROFILE_ERROR,
  DATA_DELETE_OFFER,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS_SUCCESS,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS_ERROR,
  DATA_GET_ALL_PROCESSED_CANDIDATES,
  DATA_GET_ALL_PROCESSED_CANDIDATES_SUCCESS,
  DATA_GET_ALL_PROCESSED_CANDIDATES_ERROR,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES_SUCCESS,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES_ERROR,
  DATA_UPDATE_CANDIDATOS_APUNTADOS_FILTER,
  DATA_CLEAR_CANDIDATOS_APUNTADOS_FILTER,
  DATA_UPDATE_CANDIDATOS_FILTER,
  DATA_CLEAR_CANDIDATOS_FILTER,
  DATA_UPDATE_ENTERPRISE_STATUS,
  DATA_UPDATE_ENTERPRISE_STATUS_SUCCESS,
  DATA_UPDATE_ENTERPRISE_STATUS_ERROR
} from '../actions';

export const getAllEnterprises = (filter) => ({
  type: DATA_GET_ALL_ENTERPRISES,
  payload: {filter}
});

export const getAllEnterprisesSuccess = (enterpriseList) => ({
  type: DATA_GET_ALL_ENTERPRISES_SUCCESS,
  payload: enterpriseList,
});

export const getAllEnterprisesError = (message) => ({
  type: DATA_GET_ALL_ENTERPRISES_ERROR,
  payload: { message },
});

export const updateEnterpriseStatus = ( enterpriseUid, status) => ({
  type: DATA_UPDATE_ENTERPRISE_STATUS,
  payload: {enterpriseUid, status}
});

export const updateEnterpriseStatusSuccess = (enterprise) => ({
  type: DATA_UPDATE_ENTERPRISE_STATUS_SUCCESS,
  payload: enterprise,
});

export const updateEnterpriseStatusError = (message) => ({
  type: DATA_UPDATE_ENTERPRISE_STATUS_ERROR,
  payload: { message },
});

export const getClearAllCandidates = () => {
  return {
    type: DATA_CLEAR_ALL_CANDIDATES,
  };
};

export const getAllCandidates = (uidsList = [], filter={}) => {
  return {
    type: DATA_GET_ALL_CANDIDATES,
    payload: {uidsList, filter},
  };
};

export const setCandidatosFilter = (filter) => ({
  type: DATA_UPDATE_CANDIDATOS_FILTER,
  payload: filter,
});

export const clearCandidatosFilter = () => ({
  type: DATA_CLEAR_CANDIDATOS_FILTER,
});



export const setCandidatosApuntadosFilter = (filter) => ({
  type: DATA_UPDATE_CANDIDATOS_APUNTADOS_FILTER,
  payload: filter,
});

export const clearCandidatosApuntadosFilter = () => ({
  type: DATA_CLEAR_CANDIDATOS_APUNTADOS_FILTER,
});

export const getAllCandidatesSuccess = (candidatesList) => ({
  type: DATA_GET_ALL_CANDIDATES_SUCCESS,
  payload: candidatesList,
});

export const getAllCandidatesError = (message) => ({
  type: DATA_GET_ALL_CANDIDATES_ERROR,
  payload: { message },
});

export const getAllProcessedCandidates = (idOffer, filter={}) => {
  return {
    type: DATA_GET_ALL_PROCESSED_CANDIDATES,
    payload: {idOffer, filter},
  };
};

export const getAllProcessedCandidatesSuccess = (candidatesList) => ({
  type: DATA_GET_ALL_PROCESSED_CANDIDATES_SUCCESS,
  payload: candidatesList,
});

export const getAllProcessedCandidatesError = (message) => ({
  type: DATA_GET_ALL_PROCESSED_CANDIDATES_ERROR,
  payload: { message },
});

export const getAllUnProcessedCandidates = (idOffer, filter={}, candidateFilter={}) => {
  return {
    type: DATA_GET_ALL_UNPROCESSED_CANDIDATES,
    payload: {idOffer, filter, candidateFilter},
  };
};

export const getAllUnProcessedCandidatesSuccess = (candidatesList) => ({
  type: DATA_GET_ALL_UNPROCESSED_CANDIDATES_SUCCESS,
  payload: candidatesList,
});

export const getAllUnProcessedCandidatesError = (message) => ({
  type: DATA_GET_ALL_UNPROCESSED_CANDIDATES_ERROR,
  payload: { message },
});



export const getAllOffers = (statusList) => ({
  type: DATA_GET_ALL_OFFERS,
  payload: statusList,
});

export const getAllOffersSuccess = (candidatesList) => ({
  type: DATA_GET_ALL_OFFERS_SUCCESS,
  payload: candidatesList,
});

export const getAllOffersError = (message) => ({
  type: DATA_GET_ALL_OFFERS_ERROR,
  payload: { message },
});

export const getAdminDeleteOffer = (idOffer) => ({
  type: DATA_DELETE_OFFER,
  payload: idOffer,
});

export const getCandidateProfile = (uid) => ({
  type: DATA_GET_CANDIDATE_DETAIL,
  payload: uid,
});

export const getCandidateProfileSuccess = (candidate) => ({
  type: DATA_GET_CANDIDATE_DETAIL_SUCCESS,
  payload: candidate,
});

export const getCandidateProfileError = (message) => ({
  type: DATA_GET_CANDIDATE_DETAIL_ERROR,
  payload: { message },
});

export const getCandidateCandidaturas = (uid, filter) => ({
  type: DATA_UPDATE_CANDIDATE_CANDIDATURAS,
  payload: { uid, filter },
});

export const getCandidateCandidaturasSuccess = (candidaturas) => ({
  type: DATA_UPDATE_CANDIDATE_CANDIDATURAS_SUCCESS,
  payload: candidaturas,
});

export const getCandidateCandidaturasError = (message) => ({
  type: DATA_UPDATE_CANDIDATE_CANDIDATURAS_ERROR,
  payload: { message },
});

export const getUpdateCandidateProfile = (uid) => ({
  type: DATA_UPDATE_CANDIDATE_PROFILE,
  payload: uid,
});

export const getUpdateCandidateProfileSuccess = (candidate) => ({
  type: DATA_UPDATE_CANDIDATE_PROFILE_SUCCESS,
  payload: candidate,
});

export const getUpdateCandidateProfileError = (message) => ({
  type: DATA_UPDATE_CANDIDATE_PROFILE_ERROR,
  payload: { message },
});

export const getUpdateEnterpriseProfile = (uid) => ({
  type: DATA_UPDATE_ENTERPRISE_PROFILE,
  payload: uid,
});

export const getUpdateEnterpriseProfileSuccess = (candidate) => ({
  type: DATA_UPDATE_ENTERPRISE_PROFILE_SUCCESS,
  payload: candidate,
});

export const getUpdateEnterpriseProfileError = (message) => ({
  type: DATA_UPDATE_ENTERPRISE_PROFILE_ERROR,
  payload: { message },
});

export const getOfferCandidateProfile = (idOffer, candidateUid) => ({
  type: DATA_GET_OFFER_CANDIDATE_DETAIL,
  payload: { idOffer, candidateUid },
});

export const getOfferCandidateProfileSuccess = (candidate) => ({
  type: DATA_GET_OFFER_CANDIDATE_DETAIL_SUCCESS,
  payload: candidate,
});

export const getOfferCandidateProfileError = (message) => ({
  type: DATA_GET_OFFER_CANDIDATE_DETAIL_ERROR,
  payload: { message },
});

export const getOfferCandidateStatus = (idOffer, candidateUid) => ({
  type: DATA_GET_OFFER_CANDIDATE_STATUS,
  payload: { idOffer, candidateUid },
});

export const getOfferCandidateStatusSuccess = (candidateStatus) => ({
  type: DATA_GET_OFFER_CANDIDATE_STATUS_SUCCESS,
  payload: candidateStatus,
});

export const getOfferCandidateStatusError = (message) => ({
  type: DATA_GET_OFFER_CANDIDATE_STATUS_ERROR,
  payload: { message },
});

export const getEnterpriseProfile = (uid) => ({
  type: DATA_GET_ENTERPRISE_DETAIL,
  payload: uid,
});

export const getEnterpriseProfileSuccess = (candidate) => ({
  type: DATA_GET_ENTERPRISE_DETAIL_SUCCESS,
  payload: candidate,
});

export const getEnterpriseProfileError = (message) => ({
  type: DATA_GET_ENTERPRISE_DETAIL_ERROR,
  payload: { message },
});

export const getOfferDetail = (offerId) => {
  return {
    type: DATA_GET_OFFER_DETAIL,
    payload: offerId,
  };
};

export const getOfferDetailSuccess = (offerDetail) => {
  return {
    type: DATA_GET_OFFER_DETAIL_SUCCESS,
    payload: offerDetail,
  };
};

export const getOfferDetailError = (message) => {
  return {
    type: DATA_GET_OFFER_DETAIL_ERROR,
    payload: { message },
  };
};

export const getSetOfferStatus = (payload) => {
  return {
    type: DATA_SET_OFFER_STATUS,
    payload: payload,
  };
};

export const getSetOfferStatusSuccess = (offer) => {
  return {
    type: DATA_SET_OFFER_STATUS_SUCCESS,
    payload: offer,
  };
};

export const getSetOfferStatusError = (message) => {
  return {
    type: DATA_SET_OFFER_STATUS_ERROR,
    payload: { message },
  };
};

export const getAddCandidateToOffer = (offerId, candidateUid, history) => ({
  type: DATA_ADD_CANDIDATE_TO_OFFER,
  payload: { offerId, candidateUid, history },
});

export const getAddCandidateToOfferSuccess = (response) => ({
  type: DATA_ADD_CANDIDATE_TO_OFFER_SUCCESS,
  payload: response,
});

export const getAddCandidateToOfferError = (message) => ({
  type: DATA_ADD_CANDIDATE_TO_OFFER_ERROR,
  payload: { message },
});

export const getSetCandidateStatus = (
  idOffer,
  appliedCandidate,
  action,
  history,
  redirectionHistoryState
) => ({
  type: DATA_SET_CANDIDATE_STATUS,
  payload: { idOffer, appliedCandidate, action, history,redirectionHistoryState  },
});

export const getSetCandidateStatusSuccess = (response) => ({
  type: DATA_SET_CANDIDATE_STATUS_SUCCESS,
  payload: response,
});

export const getSetCandidateStatusError = (message) => ({
  type: DATA_SET_CANDIDATE_STATUS_ERROR,
  payload: { message },
});

export const getFilterCanidates = (filterObj) => ({
  type: DATA_FILTER_CANDIDATES,
  payload: filterObj,
});

export const getFilterCanidatesSuccess = (canidateList) => ({
  type: DATA_FILTER_CANDIDATES_SUCCESS,
  payload: canidateList,
});

export const getFilterCanidatesError = (message) => ({
  type: DATA_FILTER_CANDIDATES_ERROR,
  payload: { message },
});

export const getFilterOffers = (filterObj) => ({
  type: DATA_FILTER_OFFERS,
  payload: filterObj,
});

export const getFilterOffersSuccess = (offersList) => ({
  type: DATA_FILTER_OFFERS_SUCCESS,
  payload: offersList,
});

export const getFilterOffersError = (message) => ({
  type: DATA_FILTER_OFFERS_ERROR,
  payload: { message },
});

export const getFilterEnterprises = (filterObj) => ({
  type: DATA_FILTER_ENTERPRISES,
  payload: filterObj,
});

export const getFilterEnterprisesSuccess = (enterpriseList) => ({
  type: DATA_FILTER_ENTERPRISES_SUCCESS,
  payload: enterpriseList,
});

export const getFilterEnterprisesError = (message) => ({
  type: DATA_FILTER_ENTERPRISES_ERROR,
  payload: { message },
});
