import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { SURVEY_GET_DETAILS, SURVEY_DELETE_QUESTION } from '../actions';

import {
  getSurveyDetailSuccess,
  getSurveyDetailError,
  saveSurvey,
} from './actions';

import { fetchConToken } from '../../helpers/fetch';
import { getCurrentUser } from '../../helpers/Utils';

const getSurveyDetailRequest = async (payload) => {
  const idOffer = payload
  const {role} = getCurrentUser()
  try {
      if (role === 1){
         const oferta = await fetchConToken(`auth/ofertas/user/oferta/${idOffer}`, idOffer, 'GET')
         const body = await oferta.json();
   
         if (body.ok){
           const { msg } = body
           return msg;
   
         }else {
             console.log("Error al leer la oferta");
         }

        } else {
          const oferta = await fetchConToken(`auth/ofertas/enterprise/${idOffer}`, idOffer, 'GET')

            const body = await oferta.json();

            if (body.ok){
              const { msg } = body
              return msg

            }else {
                //console.log("Error al leer la oferta");
                throw Error('An error occurred')
            }
        }
      

  } catch (error) {
          //console.log("Error del valor del servidor: ", error);
          throw Error('An error occurred')
  }

};

const deleteQuestionRequest = async (quesitonId, survey) => {
  survey.questions = survey.questions.filter((x) => x.id !== quesitonId);
  return await new Promise((success, fail) => {
    success(survey);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getSurveyDetailItems({payload}) {
  try {
    const response = yield call(getSurveyDetailRequest, payload);
    yield put(getSurveyDetailSuccess(response));
  } catch (error) {
    yield put(getSurveyDetailError(error));
  }
}

function* deleteQuestion({ payload }) {
  try {
    const { questionId, survey } = payload;
    const response = yield call(deleteQuestionRequest, questionId, survey);
    yield put(saveSurvey(response));
  } catch (error) {
    yield put(getSurveyDetailError(error));
  }
}

export function* watchGetDetail() {
  yield takeEvery(SURVEY_GET_DETAILS, getSurveyDetailItems);
}

export function* watchDeleteQuestion() {
  yield takeEvery(SURVEY_DELETE_QUESTION, deleteQuestion);
}

export default function* rootSaga() {
  yield all([fork(watchGetDetail), fork(watchDeleteQuestion)]);
}
