import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  OFERTAS_APPLY,
  OFERTAS_LIST_USER,
  OFERTAS_CREAR,
  OFERTAS_BORRAR,
  getAdminDeleteOffer,
} from '../actions';

import {
  addCandidateToOfferSuccess,
  addCandidateToOfferError,
  getCandidateSuccess,
  getCandidateError,
  deleteOfferSuccess,
  deleteOfferError,
} from './actions';

import { fetchConToken } from '../../helpers/fetch';
import { showNotification } from '../notifications/actions';
import { NotificationTypes } from '../../constants/UIConstants';

export const getCompanyAvailability = (companyId) => {
  if(!companyId) return null;
  return fetchConToken(
    `auth/interview/enterprise/${companyId}/availability`,
    companyId,
    'GET'
  ).then(res => res.json()).then(body => {
    if (body.ok) return body.msg;
    else return null;
  })
}

export const pushCompanyInterview = (companyId, offerId, candidateId, isoDate) => {
  if(!companyId) return null;
  return fetchConToken(
    `auth/interview/enterprise/${companyId}/availability`,
    { offerId, candidateId, isoDate },
    'POST'
  ).then(res => res.json()).then(body => {
    if (body.ok) return body.msg;
    else return null;
  })
}

const addCandidateToOfferRequest = async ({ payload }) => {
  const { ofertaId } = payload;
  try {
    const ofertaAplicada = await fetchConToken(
      'auth/ofertas/user/inscribirse',
      { payload: ofertaId },
      'PATCH'
    );
    const body = await ofertaAplicada.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      //console.log('Error al añadir al candidato a la oferta');
      throw Error('An error occurred')
    }
  } catch (error) {
    //console.log('Error del valor del servidor: ', error);
    throw Error('An error occurred')
  }
};

function* addCandidateToOffer(payload) {
  const history = payload?.payload?.history;
  try {
    const response = yield call(addCandidateToOfferRequest, payload);
    yield put(addCandidateToOfferSuccess(response));
    yield put(
      showNotification(
        NotificationTypes.SUCCESS,
        'Ya estas inscrito',
        `Te has inscrito a la oferta ${payload?.payload?.surveyDetails?.title}`,
        5000
      )
    );
    if (history) history.push('/app/user/ofertas');
  } catch (error) {
    yield put(
      showNotification(
        NotificationTypes.ERROR,
        'No se puede aplicar',
        `Se produjo un error al postularse para el trabajo`,
        5000
      )
    );
    yield put(addCandidateToOfferError(error));
  }
}

const deleteOfferRequest = async ({ payload }) => {
  const { idOffer } = payload;
  try {
    const ofertaAplicada = await fetchConToken(
      `auth/ofertas/enterprise/oferta/delete/${idOffer}`,
      { idOffer },
      'DELETE'
    );
    const body = await ofertaAplicada.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      //console.log('Error al añadir al candidato a la oferta');
      throw Error('An error occurred')
    }
  } catch (error) {
    //console.log('Error del valor del servidor: ', error);
    throw Error('An error occurred')
  }
};

function* deleteOffer(payload) {
  const history = payload?.payload?.history;
  try {
    const response = yield call(deleteOfferRequest, payload);
    yield put(deleteOfferSuccess(response));
    yield put(getAdminDeleteOffer(payload?.payload?.idOffer));
    if (history) history.goBack();
  } catch (error) {
    yield put(deleteOfferError(error));
  }
}

const getCandidateRequest = async ({ payload = {} } = {}) => {
  try {
    const listaOfertasApuntado = await fetchConToken(
      'auth/ofertas/user/candidaturas',
      { filter: { ...payload } },
      'POST'
    );
    const body = await listaOfertasApuntado.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      //console.log('Error al leer las ofertas en las que esta apuntado el candidato'); 
      throw Error('An error occurred')
    }
  } catch (error) {
    //console.log('Error del valor del servidor: ', error);
    throw Error('An error occurred')
  }
};

function* getCandidate(payload) {
  try {
    const response = yield call(getCandidateRequest, payload);
    yield put(getCandidateSuccess(response));
  } catch (error) {
    yield put(getCandidateError(error));
  }
}



function* createOfferEnterprise(payload) {
  try {
    const response = yield call(getCandidateRequest);
    yield put(getCandidateSuccess(response));
  } catch (error) {
    yield put(getCandidateError(error));
  }
}

export function* watchApplyJob() {
  yield takeEvery(OFERTAS_APPLY, addCandidateToOffer);
}

export function* watchGetCandidate() {
  yield takeEvery(OFERTAS_LIST_USER, getCandidate);
}

export function* watcCreateOffer() {
  yield takeEvery(OFERTAS_CREAR, createOfferEnterprise);
}
export function* watcDeleteOffer() {
  yield takeEvery(OFERTAS_BORRAR, deleteOffer);
}

export default function* rootSaga() {
  yield all([
    fork(watchApplyJob),
    fork(watchGetCandidate),
    fork(watcCreateOffer),
    fork(watcDeleteOffer),
  ]);
}
