import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCurrentUser } from '../../helpers/Utils';

import {
  SURVEY_LIST_GET_LIST,
  SURVEY_LIST_GET_LIST_PUBLIC,
  SURVEY_LIST_ADD_ITEM,
  SURVEY_LIST_GET_LIST_ANONYMOUS_PUBLIC,
  SURVEY_LIST_EDIT_ITEM,
} from '../actions';

import {
  getSurveyListSuccess,
  getSurveyListError,
  addSurveyItemSuccess,
  addSurveyItemError,
  getSurveyListPublicSuccess,
  getSurveyListPublicError,
  getSurveyListPublicAnonymousSuccess,
  getSurveyListPublicAnonymousError,
  editSurveyItemSuccess,
  editSurveyItemError,
} from './actions';

import { fetchConToken, fetchSinToken } from '../../helpers/fetch';
import {
  getUpdateSurveyDetailsError,
  getUpdateSurveyDetailsSuccess,
} from '../surveyDetail/actions';
import { UserRole } from '../../helpers/authHelper';

const getSurveyListRequest = async ({ payload = {} } = {}) => {

  try {
    const list = await fetchConToken(
      'auth/ofertas/enterprise',
      { ...payload },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
    }
  } catch (error) {
    throw error;
  }
};

function* getSurveyListItems(payload) {
  try {
    const response = yield call(getSurveyListRequest, payload);
    yield put(getSurveyListSuccess(response));
  } catch (error) {
    yield put(getSurveyListError(error));
  }
}

const getSurveyListPublicRequest = async ({ payload = {} } = {}) => {

  try {
    const list = await fetchConToken(
      'auth/ofertas',
      { filter: { ...payload } },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } 
  } catch (error) {
    throw error;
  }
};

function* getSurveyListPublicItems(payload) {
  try {
    const response = yield call(getSurveyListPublicRequest, payload);
    yield put(getSurveyListPublicSuccess(response));
  } catch (error) {
    yield put(getSurveyListPublicError(error));
  }
}

const getSurveyListPublicAnonymousRequest = async ({payload}) => {

  try {
    const list = await fetchSinToken('ofertasPublicas', {filter:{...payload}}, 'POST');
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
    }
  } catch (error) {
    throw error;
  }

};

function* getSurveyListPublicAnonymousItems(payload) {

  try {
    const response = yield call(getSurveyListPublicAnonymousRequest, payload);

    yield put(getSurveyListPublicAnonymousSuccess(response));
  } catch (error) {
    yield put(getSurveyListPublicAnonymousError(error));
  }
}

const addSurveyItemRequest = async (payload) => {

  let user = getCurrentUser();

  try {
    let route = 'auth/ofertas/enterprise/oferta/create';
    if (user && user.role === UserRole.Admin) {
      route = 'auth/ofertas/admin/oferta/create';
    }
    const ofertaCreada = await fetchConToken(route, { payload }, 'POST');

    const body = await ofertaCreada.json();

    if (body.ok) {

      const { msg } = body;
      return msg;
    } else {

      throw Error('Error creando ofertas');
    }
  } catch (error) {
    throw error;
  }
};

function* addSurveyItem({ payload }) {
  try {
    const {history, offer, redirectionLink}=payload;
    const response = yield call(addSurveyItemRequest, offer);
    yield put(addSurveyItemSuccess(response));
    if(history&&redirectionLink)
    {
      history.push(redirectionLink);
    }
  } catch (error) {
    yield put(addSurveyItemError(error));
  }
}

const editSurveyItemRequest = async (payload) => {

  try {
    const ofertaCreada = await fetchConToken(
      `auth/ofertas/enterprise/edit/oferta/${payload.idOffer}`,
      { payload },
      'POST'
    );;
    const body = await ofertaCreada.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {

      throw Error('Error editando ofertas');
    }
  } catch (error) {
    throw error;
  }
};

function* editSurveyItem({ payload }) {
  try {
    const response = yield call(editSurveyItemRequest, payload);
    yield put(editSurveyItemSuccess(response));
    yield put(getUpdateSurveyDetailsSuccess(response));
  } catch (error) {
    yield put(editSurveyItemError(error));
    yield put(getUpdateSurveyDetailsError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(SURVEY_LIST_GET_LIST, getSurveyListItems);
}

export function* watchGetPublicList() {
  yield takeLatest(SURVEY_LIST_GET_LIST_PUBLIC, getSurveyListPublicItems);
}

export function* watchGetPublicAnonymousList() {
  yield takeEvery(
    SURVEY_LIST_GET_LIST_ANONYMOUS_PUBLIC,
    getSurveyListPublicAnonymousItems
  );
}

export function* wathcAddItem() {
  yield takeEvery(SURVEY_LIST_ADD_ITEM, addSurveyItem);
}

export function* wathcEditItem() {
  yield takeEvery(SURVEY_LIST_EDIT_ITEM, editSurveyItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetPublicList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(watchGetPublicAnonymousList),
  ]);
}
