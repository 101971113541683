/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH USER*/
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_EMAIL_PASSWORD = 'LOGIN_USER_EMAIL_PASSWORD';
export const LOGIN_USER_FACEBOOK = 'LOGIN_USER_FACEBOOK';
export const LOGIN_USER_LINKEDIN = 'LOGIN_USER_LINKEDIN';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const LOGIN_SOCIAL_LINKEDIN = 'LOGIN_SOCIAL_LINKEDIN';
export const LOGIN_SOCIAL_LINKEDIN_SUCCESS = 'LOGIN_SOCIAL_LINKEDIN_SUCCESS';
export const LOGIN_SOCIAL_LINKEDIN_ERROR = 'LOGIN_SOCIAL_LINKEDIN_ERROR';

export const LOGIN_SOCIAL = 'LOGIN_SOCIAL';

/* AUTH ENTERPRISE*/
export const LOGIN_COMPANY = 'LOGIN_COMPANY';
export const LOGIN_COMPANY_SUCCESS = 'LOGIN_COMPANY_SUCCESS';
export const LOGIN_COMPANY_EMAIL_PASSWORD = 'LOGIN_COMPANY_EMAIL_PASSWORD';
export const LOGIN_COMPANY_ERROR = 'LOGIN_COMPANY_ERROR';
export const REGISTER_COMPANY = 'REGISTER_COMPANY';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_ERROR = 'REGISTER_COMPANY_ERROR';
export const LOGOUT_COMPANY = 'LOGOUT_COMPANY';
export const FORGOT_PASSWORD_COMPANY = 'FORGOT_PASSWORD_COMPANY';
export const FORGOT_PASSWORD_SUCCESS_COMPANY = 'FORGOT_PASSWORD_SUCCESS_COMPANY';
export const FORGOT_PASSWORD_ERROR_COMPANY = 'FORGOT_PASSWORD_ERROR_COMPANY';
export const RESET_PASSWORD_COMPANY = 'RESET_PASSWORD_COMPANY';
export const RESET_PASSWORD_SUCCESS_COMPANY = 'RESET_PASSWORD_SUCCESS_COMPANY';
export const RESET_PASSWORD_ERROR_COMPANY = 'RESET_PASSWORD_ERROR_COMPANY';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';


/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_EDIT_ITEM = 'SURVEY_LIST_EDIT_ITEM';
export const SURVEY_LIST_EDIT_ITEM_SUCCESS = 'SURVEY_LIST_EDIT_ITEM_SUCCESS';
export const SURVEY_LIST_EDIT_ITEM_ERROR = 'SURVEY_LIST_EDIT_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE = 'SURVEY_LIST_SELECTED_ITEMS_CHANGE';
export const SURVEY_LIST_GET_LIST_PUBLIC = 'SURVEY_LIST_GET_LIST_PUBLIC';
export const SURVEY_LIST_GET_LIST_PUBLIC_SUCCESS = 'SURVEY_LIST_GET_LIST_PUBLIC_SUCCESS';
export const SURVEY_LIST_GET_LIST_PUBLIC_ERROR = 'SURVEY_LIST_GET_LIST_PUBLIC_ERROR';
export const SURVEY_LIST_GET_LIST_ANONYMOUS_PUBLIC = 'SURVEY_LIST_GET_LIST_ANONYMOUS_PUBLIC'
export const SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_SUCCESS = 'SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_SUCCESS'
export const SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_ERROR = 'SURVEY_LIST_GET_LIST_PUBLIC_ANONYMOUS_ERROR'

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';

export const SURVEY_UPDATE_DETAILS = 'SURVEY_UPDATE_DETAILS';
export const SURVEY_UPDATE_DETAILS_SUCCESS = 'SURVEY_UPDATE_DETAILS_SUCCESS';
export const SURVEY_UPDATE_DETAILS_ERROR = 'SURVEY_UPDATE_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

/* PROFILE */
export const PROFILE_GET_USER = 'PROFILE_GET_USER'
export const PROFILE_GET_ENTERPRISE = 'PROFILE_GET_ENTERPRISE'
export const PROFILE_GET_USER_SUCCESS  = 'PROFILE_GET_USER_SUCCESS '
export const PROFILE_GET_ENTERPRISE_SUCCESS = 'PROFILE_GET_ENTERPRISE_SUCCESS'
export const PROFILE_GET_USER_ERROR = 'PROFILE_GET_USER_ERROR'
export const PROFILE_GET_ENTERPRISE_ERROR = 'PROFILE_GET_ENTERPRISE_ERROR'
export const PROFILE_ADD_USER = 'PROFILE_ADD_USER'
export const PROFILE_ADD_USER_SUCCESS = 'PROFILE_ADD_USER_SUCCESS'
export const PROFILE_ADD_ENTERPRISE = 'PROFILE_ADD_ENTERPRISE'
export const PROFILE_ADD_ENTERPRISE_SUCCESS = 'PROFILE_ADD_ENTERPRISE_SUCCESS'
export const PROFILE_ADD_USER_ERROR = 'PROFILE_ADD_USER_ERROR'
export const PROFILE_ADD_ENTERPRISE_ERROR = 'PROFILE_ADD_ENTERPRISE_ERROR'
export const PROFILE_GET_IMAGE = 'PROFILE_GET_IMAGE'
export const PROFILE_GET_IMAGE_SUCCESS = 'PROFILE_GET_IMAGE_SUCCESS'
export const PROFILE_GET_IMAGE_ERROR = 'PROFILE_GET_IMAGE_ERROR'
export const PROFILE_ADD_IMAGE = 'PROFILE_ADD_IMAGE'
export const PROFILE_ADD_IMAGE_SUCCESS = 'PROFILE_ADD_IMAGE_SUCCESS'
export const PROFILE_ADD_IMAGE_ERROR = 'PROFILE_ADD_IMAGE_ERROR'
export const PROFILE_ADD_PDF = 'PROFILE_ADD_PDF'
export const PROFILE_ADD_PDF_SUCCESS = 'PROFILE_ADD_PDF_SUCCESS'
export const PROFILE_ADD_PDF_ERROR = 'PROFILE_ADD_PDF_ERROR'

/* OFERTAS */
export const OFERTAS_APPLY = 'OFERTAS_APPLY'
export const OFERTAS_APPLY_SUCCESS = 'OFERTAS_APPLY_SUCCESS'
export const OFERTAS_APPLY_ERROR = 'OFERTAS_APPLY_ERROR'
export const OFERTAS_LIST_USER = 'OFERTAS_LIST_USER'
export const OFERTAS_LIST_USER_SUCCESS = 'OFERTAS_LIST_USER_SUCCESS'
export const OFERTAS_LIST_USER_ERROR = 'OFERTAS_LIST_USER_ERROR'
export const OFERTAS_CREAR = 'OFERTAS_CREAR'
export const OFERTAS_CREAR_SUCCESS = 'OFERTAS_CREAR_SUCCESS'
export const OFERTAS_CREAR_ERROR = 'OFERTAS_CREAR_ERROR'

export const OFERTAS_BORRAR = 'OFERTAS_BORRAR'
export const OFERTAS_BORRAR_SUCCESS = 'OFERTAS_BORRAR_SUCCESS'
export const OFERTAS_BORRAR_ERROR = 'OFERTAS_BORRAR_ERROR'


/* ADMIN_DATA */
export const DATA_GET_ALL_ENTERPRISES = 'DATA_GET_ALL_ENTERPRISES'
export const DATA_GET_ALL_ENTERPRISES_SUCCESS = 'DATA_GET_ALL_ENTERPRISES_SUCCESS'
export const DATA_GET_ALL_ENTERPRISES_ERROR = 'DATA_GET_ALL_ENTERPRISES_ERROR'
export const DATA_GET_ALL_CANDIDATES = 'DATA_GET_ALL_CANDIDATES'
export const DATA_GET_ALL_CANDIDATES_SUCCESS = 'DATA_GET_ALL_CANDIDATES_SUCCESS'
export const DATA_GET_ALL_CANDIDATES_ERROR = 'DATA_GET_ALL_CANDIDATES_ERROR'
export const DATA_CLEAR_ALL_CANDIDATES = 'DATA_CLEAR_ALL_CANDIDATES'
export const DATA_GET_ALL_OFFERS = 'DATA_GET_ALL_OFFERS'
export const DATA_GET_ALL_OFFERS_SUCCESS = 'DATA_GET_ALL_OFFERS_SUCCESS'
export const DATA_GET_ALL_OFFERS_ERROR = 'DATA_GET_ALL_OFFERS_ERROR'


export const DATA_DELETE_OFFER = 'DATA_DELETE_OFFER'

export const DATA_GET_CANDIDATE_DETAIL = 'DATA_GET_CANDIDATE_DETAIL'
export const DATA_GET_CANDIDATE_DETAIL_SUCCESS = 'DATA_GET_CANDIDATE_DETAIL_SUCCESS'
export const DATA_GET_CANDIDATE_DETAIL_ERROR = 'DATA_GET_CANDIDATE_DETAIL_ERROR'

export const DATA_UPDATE_CANDIDATE_PROFILE = 'DATA_UPDATE_CANDIDATE_PROFILE'
export const DATA_UPDATE_CANDIDATE_PROFILE_SUCCESS = 'DATA_UPDATE_CANDIDATE_PROFILE_SUCCESS'
export const DATA_UPDATE_CANDIDATE_PROFILE_ERROR = 'DATA_UPDATE_CANDIDATE_PROFILE_ERROR'

export const DATA_UPDATE_CANDIDATE_CANDIDATURAS = 'DATA_UPDATE_CANDIDATE_CANDIDATURAS'
export const DATA_UPDATE_CANDIDATE_CANDIDATURAS_SUCCESS = 'DATA_UPDATE_CANDIDATE_CANDIDATURAS_SUCCESS'
export const DATA_UPDATE_CANDIDATE_CANDIDATURAS_ERROR = 'DATA_UPDATE_CANDIDATE_CANDIDATURAS_ERROR'

export const DATA_GET_ALL_PROCESSED_CANDIDATES = 'DATA_GET_ALL_PROCESSED_CANDIDATES'
export const DATA_GET_ALL_PROCESSED_CANDIDATES_SUCCESS = 'DATA_GET_ALL_PROCESSED_CANDIDATES_SUCCESS'
export const DATA_GET_ALL_PROCESSED_CANDIDATES_ERROR = 'DATA_GET_ALL_PROCESSED_CANDIDATES_ERROR'

export const DATA_GET_ALL_UNPROCESSED_CANDIDATES = 'DATA_GET_ALL_UNPROCESSED_CANDIDATES'
export const DATA_GET_ALL_UNPROCESSED_CANDIDATES_SUCCESS = 'DATA_GET_ALL_UNPROCESSED_CANDIDATES_SUCCESS'
export const DATA_GET_ALL_UNPROCESSED_CANDIDATES_ERROR = 'DATA_GET_ALL_UNPROCESSED_CANDIDATES_ERROR'

export const DATA_UPDATE_ENTERPRISE_STATUS = 'DATA_UPDATE_ENTERPRISE_STATUS'
export const DATA_UPDATE_ENTERPRISE_STATUS_SUCCESS = 'DATA_UPDATE_ENTERPRISE_STATUS_SUCCESS'
export const DATA_UPDATE_ENTERPRISE_STATUS_ERROR = 'DATA_UPDATE_ENTERPRISE_STATUS_ERROR'

export const DATA_UPDATE_CANDIDATOS_FILTER = 'DATA_UPDATE_CANDIDATOS_FILTER'
export const DATA_CLEAR_CANDIDATOS_FILTER = 'DATA_CLEAR_CANDIDATOS_FILTER'


export const DATA_UPDATE_CANDIDATOS_APUNTADOS_FILTER = 'DATA_UPDATE_CANDIDATOS_APUNTADOS_FILTER'
export const DATA_CLEAR_CANDIDATOS_APUNTADOS_FILTER = 'DATA_CLEAR_CANDIDATOS_APUNTADOS_FILTER'

export const DATA_UPDATE_ENTERPRISE_PROFILE = 'DATA_UPDATE_ENTERPRISE_PROFILE'
export const DATA_UPDATE_ENTERPRISE_PROFILE_SUCCESS = 'DATA_UPDATE_ENTERPRISE_PROFILE_SUCCESS'
export const DATA_UPDATE_ENTERPRISE_PROFILE_ERROR = 'DATA_UPDATE_ENTERPRISE_PROFILE_ERROR'

export const DATA_GET_OFFER_CANDIDATE_DETAIL = 'DATA_GET_OFFER_CANDIDATE_DETAIL'
export const DATA_GET_OFFER_CANDIDATE_DETAIL_SUCCESS = 'DATA_GET_OFFER_CANDIDATE_DETAIL_SUCCESS'
export const DATA_GET_OFFER_CANDIDATE_DETAIL_ERROR = 'DATA_GET_OFFER_CANDIDATE_DETAIL_ERROR'

export const DATA_GET_OFFER_CANDIDATE_STATUS = 'DATA_GET_OFFER_CANDIDATE_STATUS'
export const DATA_GET_OFFER_CANDIDATE_STATUS_SUCCESS = 'DATA_GET_OFFER_CANDIDATE_STATUS_SUCCESS'
export const DATA_GET_OFFER_CANDIDATE_STATUS_ERROR = 'DATA_GET_OFFER_CANDIDATE_STATUS_ERROR'

export const DATA_GET_ENTERPRISE_DETAIL = 'DATA_GET_ENTERPRISE_DETAIL'
export const DATA_GET_ENTERPRISE_DETAIL_SUCCESS = 'DATA_GET_ENTERPRISE_DETAIL_SUCCESS'
export const DATA_GET_ENTERPRISE_DETAIL_ERROR = 'DATA_GET_ENTERPRISE_DETAIL_ERROR'
export const DATA_GET_OFFER_DETAIL = 'DATA_GET_OFFER_DETAIL'
export const DATA_GET_OFFER_DETAIL_SUCCESS = 'DATA_GET_OFFER_DETAIL_SUCCESS'
export const DATA_GET_OFFER_DETAIL_ERROR = 'DATA_GET_OFFER_DETAIL_ERROR'
export const DATA_SET_OFFER_STATUS = 'DATA_SET_OFFER_STATUS'
export const DATA_SET_OFFER_STATUS_SUCCESS = 'DATA_SET_OFFER_STATUS_SUCCESS'
export const DATA_SET_OFFER_STATUS_ERROR = 'DATA_SET_OFFER_STATUS_ERROR'
export const DATA_ADD_CANDIDATE_TO_OFFER = 'DATA_ADD_CANDIDATE_TO_OFFER'
export const DATA_ADD_CANDIDATE_TO_OFFER_SUCCESS = 'DATA_ADD_CANDIDATE_TO_OFFER_SUCCESS'
export const DATA_ADD_CANDIDATE_TO_OFFER_ERROR = 'DATA_ADD_CANDIDATE_TO_OFFER_ERROR'
export const DATA_SET_CANDIDATE_STATUS = 'DATA_SET_CANDIDATE_STATUS'
export const DATA_SET_CANDIDATE_STATUS_SUCCESS = 'DATA_SET_CANDIDATE_STATUS_SUCCESS'
export const DATA_SET_CANDIDATE_STATUS_ERROR = 'DATA_SET_CANDIDATE_STATUS_ERROR'

export const DATA_FILTER_CANDIDATES = 'DATA_FILTER_CANDIDATES'
export const DATA_FILTER_CANDIDATES_SUCCESS = 'DATA_FILTER_CANDIDATES_SUCCESS'
export const DATA_FILTER_CANDIDATES_ERROR = 'DATA_FILTER_CANDIDATES_ERROR'
export const DATA_FILTER_OFFERS = 'DATA_FILTER_OFFERS'
export const DATA_FILTER_OFFERS_SUCCESS = 'DATA_FILTER_OFFERS_SUCCESS'
export const DATA_FILTER_OFFERS_ERROR = 'DATA_FILTER_OFFERS_ERROR'
export const DATA_FILTER_ENTERPRISES = 'DATA_FILTER_ENTERPRISES'
export const DATA_FILTER_ENTERPRISES_SUCCESS = 'DATA_FILTER_ENTERPRISES_SUCCESS'
export const DATA_FILTER_ENTERPRISES_ERROR = 'DATA_FILTER_ENTERPRISES_ERROR'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './profile/actions'
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
export * from './adminData/actions';
export * from './ofertas/actions'
export * from './notifications/actions'
