import {
  PROFILE_GET_USER,
  PROFILE_GET_ENTERPRISE,
  PROFILE_GET_USER_SUCCESS,
  PROFILE_GET_ENTERPRISE_SUCCESS,
  PROFILE_GET_USER_ERROR,
  PROFILE_GET_ENTERPRISE_ERROR,
  PROFILE_ADD_USER,
  PROFILE_ADD_USER_SUCCESS,
  PROFILE_ADD_ENTERPRISE,
  PROFILE_ADD_ENTERPRISE_SUCCESS,
  PROFILE_ADD_USER_ERROR,
  PROFILE_ADD_ENTERPRISE_ERROR,
  PROFILE_GET_IMAGE,
  PROFILE_GET_IMAGE_SUCCESS,
  PROFILE_GET_IMAGE_ERROR,
  PROFILE_ADD_IMAGE,
  PROFILE_ADD_IMAGE_SUCCESS,
  PROFILE_ADD_IMAGE_ERROR,
  PROFILE_ADD_PDF,
  PROFILE_ADD_PDF_SUCCESS,
  PROFILE_ADD_PDF_ERROR,
} from '../actions';

const INIT_STATE = {
  profileUser: null,
  loading:false,
  imgLoading:false,
 
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROFILE_GET_USER:
      return { ...state, loading: true };

    case PROFILE_GET_USER_SUCCESS:
      return {
        ...state,
        profileUser: action.payload,

        loading: false,
        //userProfileData: action.payload,
      };

    case PROFILE_GET_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE_GET_ENTERPRISE:
      return { ...state, loading: true };

    case PROFILE_GET_ENTERPRISE_SUCCESS:
      return {
        ...state,
        loading: false,
        allTodoItems: action.payload,
        todoItems: action.payload,
      };

    case PROFILE_GET_ENTERPRISE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE_ADD_USER:
      return {
        ...state,
        loading: true,
        //updatedProfile: action.payload,
      };

    case PROFILE_ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allTodoItems: action.payload,
        todoItems: action.payload,
      };

    case PROFILE_ADD_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROFILE_ADD_ENTERPRISE:
      return { ...state, loading: true };

    case PROFILE_ADD_ENTERPRISE_SUCCESS:
      return {
        ...state,
        loading: false,
        allTodoItems: action.payload,
        todoItems: action.payload,
      };

    case PROFILE_ADD_ENTERPRISE_ERROR:
      return { ...state, loading: false, error: action.payload };

    /* case TODO_SELECTED_ITEMS_CHANGE:
        return  { ...state, loading: true, selectedItems: action.payload };*/
    case PROFILE_GET_IMAGE:
      return {
        ...state,
        loading: true,
        imgLoading:true,
      };

    case PROFILE_GET_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        imgLoading:false,
        image: action.payload,
      };

    case PROFILE_GET_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        imgLoading:false,
        error: action.payload,
      };

    case PROFILE_ADD_IMAGE:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_ADD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        image: action.payload,
      };

    case PROFILE_ADD_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PROFILE_ADD_PDF:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_ADD_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        pdf: action.payload,
      };

    case PROFILE_ADD_PDF_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};
