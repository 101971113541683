import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  DATA_GET_ALL_ENTERPRISES,
  DATA_GET_ALL_CANDIDATES,
  DATA_GET_ALL_OFFERS,
  DATA_GET_CANDIDATE_DETAIL,
  DATA_GET_OFFER_DETAIL,
  DATA_ADD_CANDIDATE_TO_OFFER,
  DATA_SET_CANDIDATE_STATUS,
  DATA_FILTER_CANDIDATES,
  DATA_FILTER_OFFERS,
  DATA_FILTER_ENTERPRISES,
  DATA_SET_OFFER_STATUS,
  getEnterpriseProfileSuccess,
  getEnterpriseProfileError,
  DATA_GET_ENTERPRISE_DETAIL,
  getOfferCandidateProfileSuccess,
  getOfferCandidateProfileError,
  DATA_GET_OFFER_CANDIDATE_DETAIL,
  DATA_UPDATE_CANDIDATE_CANDIDATURAS,
  DATA_GET_ALL_PROCESSED_CANDIDATES,
  DATA_GET_ALL_UNPROCESSED_CANDIDATES,
  DATA_UPDATE_ENTERPRISE_STATUS,
} from '../actions';

import {
  getAllEnterprisesSuccess,
  getAllEnterprisesError,
  getAllCandidatesSuccess,
  getAllCandidatesError,
  getAllOffersSuccess,
  getAllOffersError,
  getCandidateProfileSuccess,
  getCandidateProfileError,
  getOfferDetailSuccess,
  getOfferDetailError,
  getSetOfferStatusSuccess,
  getSetOfferStatusError,
  getAddCandidateToOfferSuccess,
  getAddCandidateToOfferError,
  getSetCandidateStatusSuccess,
  getSetCandidateStatusError,
  getFilterCanidatesSuccess,
  getFilterCanidatesError,
  getFilterOffersSuccess,
  getFilterOffersError,
  getFilterEnterprisesSuccess,
  getFilterEnterprisesError,
  getCandidateCandidaturasSuccess,
  getCandidateCandidaturasError,
  getAllProcessedCandidatesSuccess,
  getAllProcessedCandidatesError,
  getAllUnProcessedCandidatesSuccess,
  getAllUnProcessedCandidatesError,
  updateEnterpriseStatusSuccess,
  updateEnterpriseStatusError,
} from './actions';

import { fetchConToken } from '../../helpers/fetch';
import {
  getSurveyDetailError,
  getSurveyDetailSuccess,
} from '../surveyDetail/actions';
import { adminRoot } from '../../constants/defaultValues';
import { OfertasEmpresasDetalleTab } from '../../constants/UIConstants';

const getAllEnterprisesListRequest = async ({ payload = {} } = {}) => {
  try {
    const list = await fetchConToken(
      'auth/ofertas/admin/enterprises',
      { filter: payload.filter },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw new Error('Error procesando la request');
    }
  } catch (error) {
    console.log('Error del valor del servidor: ', error);
  }
};

function* getAllEnterprises(payload) {
  try {
    const response = yield call(getAllEnterprisesListRequest, payload);
    yield put(getAllEnterprisesSuccess(response));
  } catch (error) {
    yield put(getAllEnterprisesError(error));
  }
}

const getAllCandidatesListRequest = async (payload) => {
  try {
    let list = [];
    if (payload && payload?.uidsList?.length) {
      list = await fetchConToken(
        'auth/ofertas/enterprise/ofertaArray/lista',
        { uidList: payload.uidsList, filter: payload.filter },
        'POST'
      );
    } else {
      list = await fetchConToken(
        'auth/ofertas/admin/candidatos',
        { filter: payload.filter },
        'POST'
      );
    }
    //console.log('Valor del listado de ofertas: ', list);
    const body = await list.json();

    if (body.ok) {
      //console.log('valor del body: ', body);
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getAllCandidates({ payload }) {
  try {
    const response = yield call(getAllCandidatesListRequest, payload);
    yield put(getAllCandidatesSuccess(response));
  } catch (error) {
    yield put(getAllCandidatesError(error));
  }
}

const getAllProcessedCandidatesListRequest = async ({ payload }) => {
  try {
    let list = [];
    if (payload.idOffer) {
      list = await fetchConToken(
        `auth/ofertas/enterprise/ofertaArray/lista/${payload.idOffer}`,
        {
          filter: { ...payload.filter },
        },
        'POST'
      );
    } else {
      list = await fetchConToken(
        'auth/ofertas/admin/candidatos',
        { filter: payload.filter },
        'POST'
      );
    }
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getAllProcessedCandidates(payload) {
  try {
    const response = yield call(getAllProcessedCandidatesListRequest, payload);
    yield put(getAllProcessedCandidatesSuccess(response));
  } catch (error) {
    yield put(getAllProcessedCandidatesError(error));
  }
}

const getAllUnProcessedCandidatesListRequest = async ({ payload }) => {
  const candidateFilter = { ...payload.candidateFilter };
  try {
    let list = [];
    if (payload.idOffer)
      list = await fetchConToken(
        `auth/ofertas/enterprise/ofertaArray/lista/${payload.idOffer}`,
        {
          idOffer: payload.idOffer,
          filter: { ...payload.filter },
          candidateFilter: candidateFilter,
          unProcessed: Object.keys(candidateFilter).length > 0,
          includeAdminRejectedCandidates: !Object.keys(candidateFilter).length,
        },
        'POST'
      );
    else {
      list = await fetchConToken(
        'auth/ofertas/admin/candidatos',
        { filter: payload.filter },
        'POST'
      );
    }
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getAllUnProcessedCandidates(payload) {
  try {
    const response = yield call(
      getAllUnProcessedCandidatesListRequest,
      payload
    );
    yield put(getAllUnProcessedCandidatesSuccess(response));
  } catch (error) {
    yield put(getAllUnProcessedCandidatesError(error));
  }
}



const getAllOffersListRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      'auth/ofertas/admin/oferta/estado',
      { statusList: payload },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getAllOffers({ payload }) {
  try {
    const response = yield call(getAllOffersListRequest, payload);
    yield put(getAllOffersSuccess(response));
  } catch (error) {
    yield put(getAllOffersError(error));
  }
}

const getSetEnterpriseStatusRequest = async ({payload}) => {
  try {
    const list = await fetchConToken(
      `profiles/admin/enterprise/verify/${payload?.enterpriseUid}`,
      { status: payload?.status },
      'POST'
    );

    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getSetEnterpriseStatus( payload ) {
  try {
    const response = yield call(getSetEnterpriseStatusRequest, payload);
    yield put(updateEnterpriseStatusSuccess(response));
  } catch (error) {
    yield put(updateEnterpriseStatusError(error));
  }
}



const getCandidateProfileRequest = async (payload) => {

  try {
    const list = await fetchConToken(
      `auth/ofertas/admin/candidatos/${payload}`,
      'GET'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getCandidateProfile({ payload }) {
  try {
    const response = yield call(getCandidateProfileRequest, payload);
    yield put(getCandidateProfileSuccess(response));
  } catch (error) {
    yield put(getCandidateProfileError(error));
  }
}

const getCandidateCandidaturasRequest = async ({ payload }) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/admin/candidatos/candidaturas/${payload.uid}`,
      { filter: { ...payload.filter } },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getCandidateCandidaturas(payload) {
  try {
    const response = yield call(getCandidateCandidaturasRequest, payload);
    yield put(getCandidateCandidaturasSuccess(response));
  } catch (error) {
    yield put(getCandidateCandidaturasError(error));
  }
}

const getOfferCandidateProfileRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/enterprise/${payload.idOffer}/candidatos/${payload.candidateUid}`,
      'GET'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getOfferCandidateProfile({ payload }) {
  try {
    const response = yield call(getOfferCandidateProfileRequest, payload);
    yield put(getOfferCandidateProfileSuccess(response));
  } catch (error) {
    yield put(getOfferCandidateProfileError(error));
  }
}

const getEnterpriseProfileRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/admin/enterprises/${payload}`,
      'GET'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getEnterpriseProfile({ payload }) {
  try {
    const response = yield call(getEnterpriseProfileRequest, payload);
    yield put(getEnterpriseProfileSuccess(response));
  } catch (error) {
    yield put(getEnterpriseProfileError(error));
  }
}

const getOfferDetailRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/enterprise/${payload}`,
      'GET'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error procesando la petición');
    }
  } catch (error) {
    throw error;
  }
};

function* getOfferDetail({ payload }) {
  try {
    const response = yield call(getOfferDetailRequest, payload);
    yield put(getOfferDetailSuccess(response));
  } catch (error) {
    yield put(getOfferDetailError(error));
  }
}

const getSetOfferStatusRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/admin/oferta/action/estado/${payload.idOffer}`,
      { status: payload.status },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getSetOfferStatus({ payload }) {
  try {
    const response = yield call(getSetOfferStatusRequest, payload);

    yield put(getSetOfferStatusSuccess(response));
    yield put(getSurveyDetailSuccess(response));
  } catch (error) {
    yield put(getSetOfferStatusError(error));
    yield put(getSurveyDetailError(error));
  }
}

const getAddCandidateToOfferRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/admin/oferta/add/candidate/${payload.candidateId}`,
      { ...payload, history: undefined },
      'POST'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getAddCandidateToOffer({ payload }) {
  try {
    const response = yield call(getAddCandidateToOfferRequest, payload);
    yield put(getAddCandidateToOfferSuccess(response));
  } catch (error) {
    yield put(getAddCandidateToOfferError(error));
  }
}

const getSetCandidateStatusRequest = async (payload) => {
  try {
    const list = await fetchConToken(
      `auth/ofertas/enterprise/oferta/action/candidate/${payload.appliedCandidate}`,
      { ...payload, history: undefined, redirectionHistoryState: undefined },
      'PATCH'
    );
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getSetCandidateStatus({ payload }) {
  try {
    const { history, redirectionHistoryState } = payload;
    const response = yield call(getSetCandidateStatusRequest, payload);
    yield put(getSetCandidateStatusSuccess(response));
    yield put(getSurveyDetailSuccess(null));
    if (history) {
      history.replace({
        pathname: `${adminRoot}/enterprise/ofertas/${payload.idOffer}`,
        state: redirectionHistoryState || {
          tab: OfertasEmpresasDetalleTab.candidatosApuntados,
        },
      });
    }
  } catch (error) {
    yield put(getSetCandidateStatusError(error));
  }
}

const getFilterCandidatesRequest = async () => {
  try {
    const list = await fetchConToken('auth/ofertas/enterprise', {}, 'POST');
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getFilterCandidates() {
  try {
    const response = yield call(getFilterCandidatesRequest);
    yield put(getFilterCanidatesSuccess(response));
  } catch (error) {
    yield put(getFilterCanidatesError(error));
  }
}

const getFilterOffersSuccessRequest = async () => {
  try {
    const list = await fetchConToken('auth/ofertas/enterprise', {}, 'POST');
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getFilterOffers() {
  try {
    const response = yield call(getFilterOffersSuccessRequest);
    yield put(getFilterOffersSuccess(response));
  } catch (error) {
    yield put(getFilterOffersError(error));
  }
}

const getFilterEnterprisesRequest = async () => {
  try {
    const list = await fetchConToken('auth/ofertas/enterprise', {}, 'POST');
    const body = await list.json();

    if (body.ok) {
      const { msg } = body;
      return msg;
    } else {
      throw Error('Error in processing request');
    }
  } catch (error) {
    throw error;
  }
};

function* getFilterEnterprises() {
  try {
    const response = yield call(getFilterEnterprisesRequest);
    yield put(getFilterEnterprisesSuccess(response));
  } catch (error) {
    yield put(getFilterEnterprisesError(error));
  }
}

export function* watchGetAllEnterprises() {
  yield takeLatest(DATA_GET_ALL_ENTERPRISES, getAllEnterprises);
}

export function* watchGetAllCandidates() {
  yield takeLatest(DATA_GET_ALL_CANDIDATES, getAllCandidates);
}

export function* watchGetCandidateCandidaturas() {
  yield takeLatest(
    DATA_UPDATE_CANDIDATE_CANDIDATURAS,
    getCandidateCandidaturas
  );
}

export function* watchGetAllOffers() {
  yield takeLatest(DATA_GET_ALL_OFFERS, getAllOffers);
}

export function* watchGetAllProcessedCandidates() {
  yield takeLatest(
    DATA_GET_ALL_PROCESSED_CANDIDATES,
    getAllProcessedCandidates
  );
}

export function* watchGetAllUnProcessedCandidates() {
  yield takeLatest(
    DATA_GET_ALL_UNPROCESSED_CANDIDATES,
    getAllUnProcessedCandidates
  );
}

export function* watchGetCandidateProfile() {
  yield takeLatest(DATA_GET_CANDIDATE_DETAIL, getCandidateProfile);
}

export function* watchGetOfferCandidateProfile() {
  yield takeLatest(DATA_GET_OFFER_CANDIDATE_DETAIL, getOfferCandidateProfile);
}

export function* watchGetEnterpriseProfile() {
  yield takeLatest(DATA_GET_ENTERPRISE_DETAIL, getEnterpriseProfile);
}

export function* watchGetOfferDetail() {
  yield takeLatest(DATA_GET_OFFER_DETAIL, getOfferDetail);
}

export function* watchSetOfferStatus() {
  yield takeEvery(DATA_SET_OFFER_STATUS, getSetOfferStatus);
}

export function* watchAddCandidateToOffer() {
  yield takeEvery(DATA_ADD_CANDIDATE_TO_OFFER, getAddCandidateToOffer);
}

export function* watchSetEnterpriseStatus() {
  yield takeEvery(DATA_UPDATE_ENTERPRISE_STATUS, getSetEnterpriseStatus);
}

export function* watchSetCandidateStatus() {
  yield takeEvery(DATA_SET_CANDIDATE_STATUS, getSetCandidateStatus);
}

export function* watchFilterCandidates() {
  yield takeEvery(DATA_FILTER_CANDIDATES, getFilterCandidates);
}

export function* watchFilterOffers() {
  yield takeEvery(DATA_FILTER_OFFERS, getFilterOffers);
}

export function* watchFilterEnterprises() {
  yield takeEvery(DATA_FILTER_ENTERPRISES, getFilterEnterprises);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllEnterprises),
    fork(watchGetAllCandidates),
    fork(watchGetAllOffers),
    fork(watchGetAllProcessedCandidates),
    fork(watchGetAllUnProcessedCandidates),
    fork(watchGetCandidateProfile),
    fork(watchGetCandidateCandidaturas),
    fork(watchGetOfferCandidateProfile),
    fork(watchGetEnterpriseProfile),
    fork(watchGetOfferDetail),
    fork(watchSetOfferStatus),
    fork(watchAddCandidateToOffer),
    fork(watchSetCandidateStatus),
    fork(watchSetEnterpriseStatus),
    fork(watchFilterCandidates),
    fork(watchFilterOffers),
    fork(watchFilterEnterprises),
  ]);
}
