import {
  OFERTAS_APPLY,
  OFERTAS_APPLY_SUCCESS,
  OFERTAS_APPLY_ERROR,
  OFERTAS_LIST_USER,
  OFERTAS_LIST_USER_SUCCESS,
  OFERTAS_LIST_USER_ERROR,
  OFERTAS_CREAR,
  OFERTAS_CREAR_SUCCESS,
  OFERTAS_CREAR_ERROR,
  OFERTAS_BORRAR,
  OFERTAS_BORRAR_SUCCESS,
  OFERTAS_BORRAR_ERROR,
} from '../actions';

const INIT_STATE = {
  oferta: null,
  loading: true,
};

const updateState = (state, props) => {
  return {
    ...state,
    ...props,
  };
};

const addOfertasToList = (state, newOferta) => {
  let oferta = state.oferta || [];
  if (!Array.isArray(oferta)) oferta = [oferta];
  let updatedOferta = oferta.map((el) => ({
    ...el,
    candidates: [...el.candidates],
    adminRejectedCandidates: [...el.adminRejectedCandidates],
  }));
  let duplicate = updatedOferta.find((el) => el.idOffer === newOferta.idOffer);
  if (!duplicate) {
    updatedOferta.push(newOferta);
  }
  return updateState(state, { oferta: updatedOferta });
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OFERTAS_APPLY:
      return { ...state, loading: false };

    case OFERTAS_APPLY_SUCCESS:
      return updateState(addOfertasToList(state, action.payload), {
        loading: true,
      });

    case OFERTAS_APPLY_ERROR:
      return { ...state, loading: true, error: action.payload };

    case OFERTAS_LIST_USER:
      return { ...state, loading: false };

    case OFERTAS_LIST_USER_SUCCESS:
      return { ...state, loading: true, oferta: action.payload };

    case OFERTAS_LIST_USER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case OFERTAS_CREAR:
      return { ...state, loading: false };

    case OFERTAS_CREAR_SUCCESS:
      return { ...state, loading: true, oferta: action.payload };

    case OFERTAS_CREAR_ERROR:
      return { ...state, loading: true, error: action.payload };

    case OFERTAS_BORRAR:
      return { ...state, loading: false };

    case OFERTAS_BORRAR_SUCCESS:
      return { ...state, loading: true, oferta: null };

    case OFERTAS_BORRAR_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
