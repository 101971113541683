const baseUrl = process.env.REACT_APP_API_URL;

const fetchSinToken = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    if ( method === 'GET' ) {
        return fetch( url );
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify( data )
        });
    }
}

const fetchConToken = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = localStorage.getItem('token') || '';
    if ( method === 'GET'||method==="DELETE" ) {
        return fetch( url, {
            method,
            headers: {
                'x-token': token
            }
        });
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify( data )
        });
    }
}

const fetchFileToken = ( endpoint, data, method = 'GET', headerConfig={} ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = localStorage.getItem('token') || '';
    if ( method === 'GET' ) {
        return fetch( url, {
            method,
            ...headerConfig,
            headers: {
                'x-token': token
            }
        });
    } else {
        return fetch( url, {
            method,
            ...headerConfig,
            headers: {
                'x-token': token
            },
            body: data
        });
    }
}



export {
    fetchSinToken,
    fetchConToken,
    fetchFileToken
}